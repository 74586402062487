import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
// import Profile from "../../profile";
import Announce from "../../registrationTopNav";
import StepProccessingBar from "../StepProccessingBar";
import { MultiSelect } from "react-multi-select-component";
import Header from "../../../Components/header";
import { useDispatch, useSelector } from "react-redux";
import { updatedDataSixth } from "../../../Store/Reducer/registrationReducer";
import { handleKeyDown, ppeOptions } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import useIsFieldMandatory from "../../../CustomHooks/useIsFieldMandatory ";
import dropDownIcon from "../../../Assets/Svg/drop-down-icon.svg";

const SupplierHSE = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const isFieldMandatory = useIsFieldMandatory();
  const [fileNames, setFileNames] = useState({});
  const [file, setFile] = useState({});
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [fileSizeError, setFileSizeError] = useState({});
  const { t } = useTranslation();
  const categoriesData = useSelector(
    (state) => state.registration.secondObject
  );

  const vendorData = useSelector((state) => state.registration.sixthObject);

  const initialSelected =
    vendorData?.ppe?.length > 0
      ? vendorData?.ppe?.map((value) => ({
          label: value,
          value,
        }))
      : [];

  const [selectedPpe, setSelectedPpe] = useState(initialSelected);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const ArrowRenderer = ({ expanded }) => (
    <>
      {expanded ? (
        <img src={dropDownIcon} alt="" />
      ) : (
        <img src={dropDownIcon} alt="" />
      )}
    </>
  );
  const onSubmit = (data) => {
    data.ppe = Array.isArray(data?.ppe)
      ? data.ppe.map((option) =>
          typeof option === "string" ? option : option?.value
        )
      : data?.ppe;

    dispatch(updatedDataSixth({ ...data, ...file }));
    
    navigate("/documentsubmission");
  };


  const onClickBack = () => {
    navigate("/quality");
  };
  const onChangeDropDownPpe = (value) => {
    setValue("ppe", value);
    setSelectedPpe(value);
  };

  const handleChange = (e) => {
    let url =
      URL.createObjectURL(e.target.files[0]) + "+" + e.target.files[0].name;
    setSelectedFileUrl(url);
    const fileSizeNumber = e.target.files[0].size / 1024 / 1024;
    if (fileSizeNumber < 20) {
      setFile((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.files[0],
      }));

      setFileNames((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.files[0].name,
      }));
      setFileSizeError((prevState) => ({
        ...prevState,
        [e.target.name]: "",
      }));
    } else {
      setFileSizeError((prevState) => ({
        ...prevState,
        [e.target.name]: "Please enter the file below 20MB size.",
      }));
    }
    const splitUrl = url.split("+");

    //const blob= new File([splitUrl[0]],splitUrl[1])
  };


  useEffect(() => {
    let fileNames = {};
    Object.entries(vendorData).forEach(([name, value]) => {
      setValue(name, value);

      if (
        [
          "ndt_personal_doc",
          "equipment_tools_doc",
          "organization_chart_doc",
          "qa_qc_policy_doc",
          "technical_doc",
        ].includes(name)
      ) {
        // fileNames = { ...fileNames, [name]: value.split("+")[1] };
        fileNames = { ...fileNames, [name]: value.name };
      }
    });
    setFileNames(fileNames);
  }, [vendorData]);

  return (
    <>
      {/* <Profile /> */}
      <div className="regstration-header">
        <Header />
      </div>
      <div className="announce-navbar">
        <Announce />
      </div>
      <div className="main-container">
        <StepProccessingBar
          screen1="completed"
          screen2="completed"
          screen3="completed"
          screen4="completed"
          screen5="completed"
          screen6="active"
          screen7="inactive"
        />
        <div className="vendor-page-container">
          <h1 className="header-registration">{t("Vendor Registration")}</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
          <div className="form-container-with-bottomspace-hse">
            <h4 className="header-registration-two">
              Supplier HSE Management (การจัดการ HSE ซัพพลายเออร์์)
            </h4>
            <div className="textbox-container">
              <div className="left-container">
                <label className="vendor-input-label">
                  {t(
                    "Does your company implement, maintain and improve anmoccupational health and safety management system?"
                  )}
                  {(categoriesData.type_of_business === "Transportation" ||
                    categoriesData.type_of_business === "Service") && (
                    <span className="staricon">*</span>
                  )}
                </label>
                <br />

                <select
                  type="text"
                  disabled={
                    categoriesData.type_of_business !== "Transportation" &&
                    categoriesData.type_of_business !== "Service"
                  }
                  className="input-border registration"
                  {...register("health_safty_management", {
                    required:
                      categoriesData.type_of_business === "Transportation" ||
                      categoriesData.type_of_business === "Service",
                  })}
                >
                  <option value="" hidden>
                    {t("select")}{" "}
                  </option>
                  <option value="Yes">{t("Yes")}</option>
                  <option value="No">{t("No")}</option>
                </select>
                {errors.health_safty_management && (
                  <span className="error-msg">
                    {t(
                      "Please select whether your company operates, maintains, and improves occupational health and safety management systems."
                    )}
                  </span>
                )}
              </div>
              <div className="right-container">
                <label className="vendor-input-label">
                  {t(
                    "Does your company provide PPE for your staff? (Multiple options can be selected.)"
                  )}
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <div
                  className="dropdown-multi"
                  style={{ marginBottom: "auto", marginTop: "0px" }}
                >
                  <Controller
                    name="ppe"
                    control={control}
                    rules={{
                      required: isFieldMandatory
                        ? t("Please select at least one ppe.")
                        : false,
                    }}
                    render={({ field, fieldState }) => (
                      <>
                        <MultiSelect
                          className="multi-select-drop-down mb-3"
                          options={ppeOptions}
                          {...field}
                          value={selectedPpe}
                          onChange={(value) => {
                            field.onChange(value);
                            onChangeDropDownPpe(value);
                          }}
                          labelledBy={t("select")}
                          hasSelectAll={false}
                          ArrowRenderer={ArrowRenderer}
                        />
                        {fieldState.error && (
                          <span className="error-msg mt-3">
                            {fieldState.error.message}
                          </span>
                        )}
                      </>
                    )}
                  />

                  {/* <DropdownMultiselect
                    selected={vendorData.ppe}
                    handleOnChange={(value) => {
                      onChangeDropDownPpe(value);
                    }}
                    {...register("ppe", {
                      required: isFieldMandatory,
                    })}
                    options={[
                      "Safety shoes",
                      "Safety helmet",
                      "Safety Glassed/Goggle",
                      "Chemical Gloves",
                      "Long sleeves shirt",
                      "Half Mask & Respirator",
                      "Other. please specify",
                    ]}
                    placeholder={t("select")}
                    name="ppe"
                    selectDeselectLabel=""
                  /> */}
                </div>
                {errors.ppe && (
                  <span className="error-msg">
                    {t(
                      "Please select whether your company provides PPE to your employees."
                    )}
                  </span>
                )}
              </div>
            </div>
            <div className="textbox-container-specify">
              {Array.isArray(selectedPpe) &&
                selectedPpe.some(
                  (option) => option?.value === "Other. please specify"
                ) && (
                  <div className="right-container-specify">
                    <br />

                    <input
                      type="text"
                      className="input-border registration-left "
                      {...register("ppe_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    {errors.ppe_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </div>
                )}
            </div>
            <div className="textbox-container">
              <div className="left-container">
                <label className="vendor-input-label">
                  {t(
                    "Have your company ever gotten any prize concerning SAFETY management?"
                  )}
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration"
                  {...register("qhse_management", {
                    required: isFieldMandatory,
                  })}
                >
                  <option value="" hidden>
                    {t("select")}{" "}
                  </option>
                  <option value="Yes">{t("Yes")}</option>
                  <option value="No">{t("No")}</option>
                </select>
                {errors.qhse_management && (
                  <span className="error-msg">
                    {t(
                      "Please select whether your company has received any awards related to SAFETY management."
                    )}
                  </span>
                )}
              </div>
              <div className="right-container">
                <label className="vendor-input-label">
                  {t(
                    "Does your company occur any accident during the year, if yes please give detail of injury / death?"
                  )}
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration"
                  {...register("injury_deaths", {
                    required: isFieldMandatory,
                  })}
                >
                  {" "}
                  <option value="" hidden>
                    {t("select")}{" "}
                  </option>
                  <option value="Yes">{t("Yes")}</option>
                  <option value="No">{t("No")}</option>
                </select>
                {errors.injury_deaths && (
                  <span className="error-msg">
                    {t(
                      "Please select whether your company has experienced any incidents during the year."
                    )}
                  </span>
                )}
              </div>
            </div>

            <div className="textbox-container-specify">
              {watch("injury_deaths") &&
                watch("injury_deaths").includes("Yes") && (
                  <div className="right-container-specify">
                    <br />

                    <input
                      type="text"
                      className="input-border registration-left "
                      {...register("injury_death_specify", {
                        required: true,
                        maxLength: 80,
                      })}
                    />
                    {errors.injury_death_specify && (
                      <span className="error-msg">
                        {t("Please enter other specific data")}
                      </span>
                    )}
                  </div>
                )}
            </div>
            <div className="textbox-container">
              <div className="left-container">
                <label className="vendor-input-label">
                  {t("Does your company have registered foreigner labor ?")}
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration"
                  {...register("registered_foreigner_labor", {
                    required: isFieldMandatory,
                  })}
                >
                  {" "}
                  <option value="" hidden>
                    {t("select")}{" "}
                  </option>
                  <option value="Yes">{t("Yes")}</option>
                  <option value="No">{t("No")}</option>
                </select>
                {errors.registered_foreigner_labor && (
                  <span className="error-msg">
                    {t("Please select the correct value")}{" "}
                  </span>
                )}
              </div>
              <div className="right-container">
                <label className="vendor-input-label">
                  {t(
                    "Does your company have registered age lower than 18 years?"
                  )}
                  {isFieldMandatory && <span className="staricon">*</span>}
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration"
                  {...register("lower18_labor", {
                    required: isFieldMandatory,
                  })}
                >
                  <option value="" hidden>
                    {t("select")}{" "}
                  </option>
                  <option value="Yes">{t("Yes")}</option>
                  <option value="No">{t("No")}</option>
                </select>
                {errors.lower18_labor && (
                  <span className="error-msg">
                    {t(
                      "Please select whether your company have registered age lower than 18 years"
                    )}
                  </span>
                )}
              </div>
            </div>

            <h4 className="text-above-the-horizantalline">
              {t(
                "If vendor click type of business is service / project must fill the Contractor/Service/Project Data as below / not mandatory for attached file."
              )}
            </h4>
            <hr className="horziantal"></hr>

            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  {" "}
                  {t("Organization Chart")}
                </label>
                <br />
                <div className="button-input-container">
                  <label className="input-border-documents-supplier-hse">
                    {fileNames.organization_chart_doc}
                  </label>

                  <div className="button-attach-container-hse">
                    <label
                      className="uploadFile-document-supplier-hse"
                      onChange={handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="organization_chart_doc"
                        accept="application/pdf"
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">
                  {fileSizeError.organization_chart_doc}
                </p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>

              <div className="right-Username-container">
                <label className="vendor-input-label">
                  {t("QA/QC Policy/Manual/Work procedure")}{" "}
                </label>
                <br />
                <div className="test-user-container">
                  <label className="input-border-documents-supplier-hse">
                    {fileNames.qa_qc_policy_doc}
                  </label>
                  <div className="button-attach-container-hse">
                    <label
                      className="uploadFile-document-supplier-hse"
                      onChange={handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="qa_qc_policy_doc"
                        accept="application/pdf"
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">{fileSizeError.qa_qc_policy_doc}</p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  {t("Technical Manual/ Document")}
                </label>
                <br />
                <div className="button-input-container">
                  <label className="input-border-documents-supplier-hse">
                    {fileNames.technical_doc}
                  </label>

                  <div className="button-attach-container-hse">
                    <label
                      className="uploadFile-document-supplier-hse"
                      onChange={handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="technical_doc"
                        accept="application/pdf"
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">{fileSizeError.technical_doc}</p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
              <div className="right-Username-container ">
                <label className="vendor-input-label">
                  {t("QA/QC, Welder, NDT personal certificate/CV")}
                </label>
                <br />
                <div className="button-input-container">
                  <label className="input-border-documents-supplier-hse">
                    {fileNames.ndt_personal_doc}
                  </label>

                  <div className="button-attach-container-hse">
                    <label
                      className="uploadFile-document-supplier-hse"
                      onChange={handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="ndt_personal_doc"
                        accept="application/pdf"
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">{fileSizeError.ndt_personal_doc}</p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
            </div>
            <div className="textbox-container">
              <div className="left-Username-container ">
                <label className="vendor-input-label">
                  {t("List of equipment and tools")}
                </label>
                <br />
                <div className="button-input-container">
                  <label className="input-border-documents-supplier-hse">
                    {fileNames.equipment_tools_doc}
                  </label>

                  <div className="button-attach-container-hse">
                    <label
                      className="uploadFile-document-supplier-hse"
                      onChange={handleChange}
                    >
                      {t("Upload File")}
                      <input
                        type="file"
                        hidden
                        name="equipment_tools_doc"
                        accept="application/pdf"
                      />
                    </label>
                  </div>
                </div>
                <p className="error-msg">{fileSizeError.equipment_tools_doc}</p>
                <p className="hse-mini">
                  {t("Maximum file size 20 MB. Supported file formats: .pdf")}
                </p>
              </div>
              <div className="right-Username-container ">
                <label className="vendor-input-label">
                  {t(
                    "Does your company inspect service & repair equipment as PM?"
                  )}
                </label>
                <br />
                <div className="button-input-container">
                  <select
                    className="input-border registration"
                    {...register("repair_equipment_pm", {
                      required: false,
                    })}
                  >
                    {" "}
                    <option value="" hidden>
                      {" "}
                      {t("select")}
                    </option>
                    <option value="Yes">{t("Yes")}</option>
                    <option value="No">{t("No")}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons">
            <div className="button-submit-container-back">
              <input
                className="button-submit-back"
                type="submit"
                value={t("Back")}
                onClick={onClickBack}
              />
            </div>
            <div className="button-submit-container">
              <button className="button-submit" type="submit">
                {t("Next")}
              </button>{" "}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SupplierHSE;
