import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./registrationTopNav.css";
import { useNavigate, useLocation } from "react-router-dom";
//active icons
import activeJobs from "../../Constants/images/announceNavbarIcons/activeJobs.png";
import activePressRelease from "../../Constants/images/announceNavbarIcons/activePressRelease.png";
import activeVendorGuide from "../../Constants/images/announceNavbarIcons/activeVendorGuide.png";
import activeVendorReg from "../../Constants/images/announceNavbarIcons/activeVendorReg.png";
// in active icons
import inactiveJobs from "../../Constants/images/announceNavbarIcons/inactiveJobs.png";
import inactivePressRelease from "../../Constants/images/announceNavbarIcons/inactivePressRelease.png";
import inactiveVendorGuide from "../../Constants/images/announceNavbarIcons/inactiveVendorGuide.png";
import inactiveVendorReg from "../../Constants/images/announceNavbarIcons/inactiveVendorReg.png";
import TermsAndConditions from "../../Components/termsandconditions";

export default function Announce() {
  const [config, setConfig] = useState({
    screen1: "active",
    screen2: "inactive",
    screen3: "inactive",
    screen4: "inactive",
  });
  const [show, setShow] = useState(false);
  const currentPage = window.location.pathname;
  const { t } = useTranslation();
  const userToken = localStorage.getItem("vms_vendor_token");
  const location = useLocation();
  let navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const registrationAllPage = [
    "/general-info",
    "/categories",
    "/financial",
    "/supplier",
    "/quality",
    "/supplierhse",
    "/documentsubmission",
  ];
  // Check if the current page is in the registrationAllPage array
  const isPageInArray = registrationAllPage.includes(currentPage);

  return (
    <div className="main-announce-card">
      <TermsAndConditions show={show} handleClose={handleClose} />
      {!userToken ? (
        <div
          className={
            registrationAllPage.includes(location.pathname)
              ? "announce-card selected"
              : "announce-card"
          }
          onClick={() => {
            if (!isPageInArray) {
              handleShow();
            }
          }}
        >
          <img
            className="announce-images"
            src={
              registrationAllPage.includes(location.pathname)
                ? activeVendorReg
                : inactiveVendorReg
            }
            alt=""
          ></img>
          <p className="announce-tags"> {t("Vendor Registration")}</p>
        </div>
      ) : null}

      <div
        className={
          location.pathname === "/jobs"
            ? "announce-card selected"
            : "announce-card"
        }
        onClick={handleClick.bind(this, "/jobs")}
      >
        {" "}
        <img
          className="announce-images"
          src={location.pathname === "/jobs" ? activeJobs : inactiveJobs}
          alt=""
        ></img>
        <p className="announce-tags">{t("Jobs")}</p>
      </div>
      <div
        className={
          location.pathname === "/press-release"
            ? "announce-card selected"
            : "announce-card"
        }
        onClick={handleClick.bind(this, "/press-release")}
      >
        {" "}
        <img
          className="announce-images"
          src={
            location.pathname === "/press-release"
              ? activePressRelease
              : inactivePressRelease
          }
          alt=""
        ></img>
        <p className="announce-tags"> {t("PressRelease")}</p>
      </div>
      <div
        className={
          location.pathname === "/vendor-guide"
            ? "announce-card selected"
            : "announce-card"
        }
        onClick={handleClick.bind(this, "/vendor-guide")}
      >
        {" "}
        <img
          className="announce-images"
          src={
            location.pathname === "/vendor-guide"
              ? activeVendorGuide
              : inactiveVendorGuide
          }
          alt=""
        ></img>
        <p className="announce-tags"> {t("Vendor Guide")}</p>
      </div>
    </div>
  );
}
