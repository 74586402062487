import React from "react";
import "./jobstable.css";
import { formatNumber } from "../../../Utils/Utils";

const JobExpandedTablePO = ({ data,PurchasingGroup}) => {

  return (
    data && (
      <div className="jobs-expanded-table-div">
        <table className="jobs-expanded-table">
          <thead>
            <tr>
              <th>
                <p style={{ visibility: "hidden" }}>Locationssss</p>
              </th>
              <th>Purchase Requisition</th>
              <th>Description</th>
              <th>Purchase Group</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>
                <p style={{ visibility: "hidden" }}>Locationssss</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.map((row, index) => (
                <tr key={index}>
                  <td>{}</td>
                  <td>{row?.PrNumber}</td>
                  <td style={{ maxWidth: "100px", wordWrap: "break-word", overflow: "hidden", whiteSpace: "normal" }}>{row?.PrShortText}</td>
                  <td >{PurchasingGroup}</td>
                  <td>{row?.PrItemNumber}</td>
                  
                  <td>{formatNumber(row?.PrQTY)}</td>
                  <td>{row?.PRUnit}</td>
                  <td>{}</td>
                  
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  );
};

export default JobExpandedTablePO;
