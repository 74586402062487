import React from "react";
import Login from "../../Components/Login/Login";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import Announce from "../registrationTopNav";
import VendorGuide from "../../Components/vendorGuide";
import "./vendor-guide.css";
import SamplePdf from "../../Assets/pdf_files_scan_create_reducefilesize.pdf";
import Header from "../../Components/header";
// import index from './index';

const VendorGuidePage = () => {
  const token = localStorage.getItem("vms_vendor_token");
  return (
    <>
      {!token ? (
        <div>
          <DefaultLayout>
            <div className="loginpage">
              <Login />
            </div>
          </DefaultLayout>
        </div>
      ) : (
        <div>
          <div className="resetcontentpage">
            <Header />
            <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
              {/* <h1 className="reset-header">
                                                                RESET YOUR<span className="password px-3">PASSWORD</span>
                                                        </h1> */}
            </div>
          </div>
        </div>
      )}
      <div className="">
        <div className="announce-navbar">
          <Announce />
        </div>

        <div className="announcement-page-background ">
          <div className="announcement-jobs-top">
            <h1 className="announcement-jobs-top-header py-2">Vendor Guide</h1>
          </div>
        </div>
      </div>

      <div className="vendor-guide-page-body">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <VendorGuide pdf={SamplePdf} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorGuidePage;
