import React from "react";
import "./jobstable.css";
import { formatNumber, formatNumberWithCommas } from "../../../Utils/Utils";

const JobExpandedTable = ({ data }) => {

  return (
    data && (
      <div className="jobs-expanded-table-div">
        <table className="jobs-expanded-table">
          <thead>
            <tr>
              
              <th>Purchase Requisition</th>
              <th>Description</th>
              <th>Purchase Group</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>
                <p style={{ visibility: "hidden" }}>Locationssss</p>
              </th>
              <th>
                <p style={{ visibility: "hidden" }}>Locationssss</p>
              </th>
              <th>
                <p style={{ visibility: "hidden" }}>Locationssss</p>
              </th>
             
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.map((row, index) => (
                <tr key={index}>
                  
                  <td>{row?.PrSapDetail?.PR_ID}</td>
                  <td style={{
                    maxWidth:"120px",
                    wordWrap: "break-word",
  whiteSpace: "normal"
                  }}>{row?.PrSapDetail?.PR_description_Short}</td>
                  <td style={{
                    maxWidth:"23px"
                  }}>{row?.PrSapDetail?.PR_Group}</td>
                  <td>{index+1}</td>
                  <td>{formatNumber(row?.PrSapDetail?.PR_QTY)}</td>
                  <td>{row?.PrSapDetail?.PR_Unit}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  );
};

export default JobExpandedTable;
