import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/header";
import "./resetContentPage.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ResetContentPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <>
      <div className="resetcontentpage">
        <Header />
        <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
          <h1 className="reset-header">
            RESET YOUR<span className="password px-3">PASSWORD</span>
          </h1>
        </div>
      </div>

      <div className="reset-content py-5">
        <p className="py-3 content">
          We recived a request to reset your password. <br />
          use the button below to setup a new password for your vendor account
        </p>
        <button
          onClick={handleNavigate("/reset-password-page")}
          className="reset-password-btn"
        >
          {t("Reset Password")}
        </button>

        <p className="py-3 content">
          For addditional help or information, email us at <br />
          <span style={{ color: "#AFCA0B", textDecoration: "underline" }}>
            support@vinythai.com{" "}
          </span>{" "}
          or visit Support website
        </p>
      </div>

      <div className="pt-1 mt-2">
        <Footer />
      </div>
    </>
  );
};

export default ResetContentPage;
