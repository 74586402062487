import React from "react";
import "./PageLoader.css";
import { ThreeDots } from "react-loader-spinner";

const PageLoader = () => {
        return (
                <>
                        <div className="loader-div">
                                <div className="page-loader">
                                        <ThreeDots
                                                height="80"
                                                width="80"
                                                radius="9"
                                                color="#003595"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                        />
                                </div>
                        </div>
                </>
        );
};

export default PageLoader;
