import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";

import "./prtable.css";
import JobExpandedTable from "../Jobs/JobsTable/JobExpandedTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import CSVDownloadButton from "../CsvDownload/CsvDownload";
import PrDetailsUnderJobs from "../../Models/PrDetailsUnderJobs/PrDetailsUnderJobs";
import { clearVendorDashboard, fetchPrDataByFilter } from "../../Store/Actions/vendor_dashboard";
import { useDispatch, useSelector } from "react-redux";
import { VendorDashboardPrDataReducer } from "../../Store/Reducer/vendorDashboard_Reducer";
import PageLoader from "../../Models/Pageloader/PageLoader";





const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#103A5D",
      color: "white",
      fontSize: "14px",
      fontFamily: "Sarabun",
      fontStyle: "normal",
      fontWeight: 400,
    },
  },
};

const ExpandedComponent = ({ data }) => (
  <pre>
    <JobExpandedTable data={data?.JobPrDetails} />
  </pre>
);

const DashboardPrTable = ({prStatusData}) => {
  function DateGenerator(data){
    if(!data){
      return null;
    }
   const date =new Date(data)
   return date.toLocaleDateString();
  }

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [orderBy, setOrderBy] = useState('updatedAt');
  const [orderDir, setOrderDir] = useState('ASC');
  const [count, setCount] = useState(prStatusData?.allCount || 0);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState(3);
  const [selectedRowData, setSelectedRowData] = useState(null);
  
  const handleTypeSelect = (selectedKey) => {
    setCurrentPage(1);
    setType(parseInt(selectedKey));
    
  };

  const {dashboardPRData:data,loading,error}= useSelector((state) => state.VendorDashboardReducer);

  useEffect(() => {
    if (dispatch) {
      dispatch(fetchPrDataByFilter(currentPage, itemsPerPage, orderBy, orderDir, type));
    }
  }, [dispatch, type, currentPage, itemsPerPage, orderBy, orderDir]);
  useEffect(()=>{
    setCount(data?.TotalCount);
  },[data,count])


  const columns = [
    {
      name: `${t("Location")}`,
      selector: (row) => row?.PR_Company || 'N/A', 
      sortable: true,
    },
    {
      name: `${t("PR No")}`,
      selector: (row) =>  (row?.JobPrDetails && row?.JobPrDetails?.length>0 ? (row?.JobPrDetails?.length > 1 ? `Combined (${row?.JobPrDetails?.length})` : row?.JobPrDetails[0]?.PrSapDetail?.PR_ID ) :"" ) || '', 
      sortable: true,
    },
    {
      name: `${t("PR Title")}`,
      selector: (row) => row?.PrTitle || 'N/A',
      sortable: true,
      width: "10%",
    },
    {
      name: `${t("Purchase Group")}`,
      selector: (row) => row?.PR_Company || 'N/A',
      sortable: true,
      width: "15%",
    },
    {
      name: "Date Start",
      selector: (row) => DateGenerator(row?.BiddingDate) || 'N/A',
      sortable: true,
    },
    {
      name: "Date End",
      selector: (row) => DateGenerator(row?.DeliveryDate) || 'N/A',
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row?.BuyerName || 'N/A',
      sortable: true,
    },
    {
      id:"Status",
      name: `${t("Status")}`,
      cell: (row) => (
        <button
        className={`dashboard-main-page-award ${type===3?(row?.jobVendorStatus===0?"status-bidding":row?.jobVendorStatus===1?"Awarded":row?.jobVendorStatus===2?"status-declined":""):type===0?"status-bidding":type===1?"Awarded":type===2?"status-declined":""}`}
          
        >
          {type===3?(row?.jobVendorStatus===0?"Bidding":row?.jobVendorStatus===1?"Awarded":row?.jobVendorStatus===2?"Declined":""):type===0?"Bidding":type===1?"Awarded":type===2?"Declined":""}
          
        </button>
      ),
    },
    {
      name: `${t("Action")}`,
      cell: (row) => (
        <button
          className="dashboard-main-page-pr-view"
          onClick={() => {
            handleViewPr(row);
          }}
        >
          View PR
        </button>
      ),
    },
  ];
  
 

  const handleViewPr = (result) => {
    setSelectedRowData(result); 
    setIsModalOpen(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };

  // const filteredDataByType =
  //   type === "all" ? data : data?.filter((item) => item.status === 1);

  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
   
  };

  // const paginatedData = filteredDataByType.slice(
  //   (currentPage - 1) * itemsPerPage,
  //   currentPage * itemsPerPage
  // );

  const headerNames = columns.map((column) => column?.name);

  //code for csvdata
  const csvData = data?.data?.map((item) =>
    columns.reduce((row, column) => {
      if(column.id==="Status"){
        row[column.name]=type===3?(item?.jobVendorStatus===0?"Bidding":item?.jobVendorStatus===1?"Awarded":item?.jobVendorStatus===2?"Declined":""):type===0?"Bidding":type===1?"Awarded":type===2?"Declined":""
      }
      else{
        row[column.name] = column.selector ? column.selector(item) : item[column.name] || '';

      }
      return row;
    }, {})
  ) || []; 

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getCountByStatus = (status) => {
    switch (status) {
      case 0:
        return prStatusData?.biddingCount || 0;
      case 1:
        return prStatusData?.awardedCount || 0;
      case 2:
        return prStatusData?.declinedCount || 0;
      default:
        return prStatusData?.allCount || 0
        
    }
  };
  return (
    <>
    {loading && <PageLoader/>}
      {isModalOpen && (
        <PrDetailsUnderJobs
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="PR detail"
          data={selectedRowData}
        />
      )}

      <div className="dahsboard-main-page-tables">
        <Nav activeKey="/home" onSelect={handleTypeSelect}>
          <Nav.Item>
            <Nav.Link
              className={
                type === 3 ? "pr-table-nav-active" : "pr-table-nav"
              }
              eventKey="3"
            >
              {t("All")}
              <span
                className={
                  type === 3 ? "pr-top-badge-active" : "pr-top-badge"
                }
                eventKey="3"
              >
                {getCountByStatus(3)}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === 0 ? "pr-table-nav-active" : "pr-table-nav"
              }
              eventKey="0"
            >
              {t("Bidding")}
              <span
                className={
                  type === 0 ? "pr-top-badge-active" : "pr-top-badge"
                }
                eventKey="0"
              >
                {getCountByStatus(0)}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === 1 ? "pr-table-nav-active" : "pr-table-nav"
              }
              eventKey="1"
            >
              {t("Awarded")}
              <span
                className={
                  type === 1 ? "pr-top-badge-active" : "pr-top-badge"
                }
                eventKey="1"
              >
                {getCountByStatus(1)}
              </span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === 2 ? "pr-table-nav-active" : "pr-table-nav"
              }
              eventKey="2"
            >
              {t("Declined")}
              <span
                className={
                  type === 2 ? "pr-top-badge-active" : "pr-top-badge"
                }
                eventKey="2"
              >
                {getCountByStatus(2)}
              </span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="d-flex justify-content-between align-items-center p-3">
          <div>
            <div className="">
              <label className="show-po-table-top" for="">
                {t("Show")}
              </label>
              <select
                className="show-select-options px-2 mx-2"
                value={itemsPerPage}
                onChange={handleDropdownChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <label className="entries-po-table-top">{t("Entries")}</label>
            </div>
          </div>
          <div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="pr_table_data.csv"
            />
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            className="vendor-dashboard-pr-table"
            columns={columns}
            data={data?.data || []}
            customStyles={customStyles}
            pagination={false}
            noDataComponent="No records to display"
            expandableRows
            expandableRowDisabled={(row) => !row.JobPrDetails || row?.JobPrDetails?.length<2}
            expandableRowExpanded={(row) => row?.JobPrDetails?.length>1}
            expandableRowsComponent={ExpandedComponent}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center pb-5">
        <p className="table-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          innerPagination={true}
        />
      </div>
    </>
  );
};

export default DashboardPrTable;
