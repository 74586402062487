import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import "./jobsfilter.css";

function convertDataToOptions(data) {
  const options = {};

  function transformArray(array, key) {
    return array.map(item => ({
      label: item?.meaning,
      value: item?.purchasinggroup_name,
    }));
  }
  for (const key in data) {
    options[key.charAt(0).toUpperCase() + key.slice(1)] = transformArray(data[key], key);
  }

  return options;
}
const modalStyle = {
  overlay: {
    backgroundColor: "",
    // position: 'relative',
    position: "absolute",
  },
};

function MultiSelectComponent({ label, selectedOptions, setSelectedOptions, options }) {
  return (
    <MultiSelect
      options={options[label] || []}
      value={selectedOptions || []}
      onChange={setSelectedOptions}
      labelledBy="Select"
      overrideStrings={{ selectSomeItems: label }}
      disableSearch={true}
      hasSelectAll={false}
      className="pr-release-multiselect"
    />
  );
}

function JobFilterPopup({
  JobFilterIsOpen,
  JobFilterCloseModal,
  onApplyFilter,
  filterList,
  setJobFilterModalOpen,
}) {
  const token = localStorage.getItem("vms_vendor_token");
  const [options, setOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
 
  const [closedByOutsideClick, setClosedByOutsideClick] = useState(false);
 
  useEffect(() => {
    const data = convertDataToOptions(filterList);
    setOptions(data || {});
  }, [filterList]);

  const handleApplyClick = () => {
    if (onApplyFilter) {
      onApplyFilter(selectedOptions?.map(option => option?.value)); 
    }
    JobFilterCloseModal();
  };


  const closeModal = () => {
    if (!closedByOutsideClick) {
      setJobFilterModalOpen(false)
    }
  };

  const handleLabelSelection = (label, newOptions) => {
    setSelectedOptions(prevSelectedOptions => {
      const updatedSelectedOptions = prevSelectedOptions.filter(
        option => !options[label].some(opt => opt.value === option.value)
      );

      return [...updatedSelectedOptions, ...newOptions];
    });
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".job-service-modal")) {
        setClosedByOutsideClick(true);
        closeModal();
      }
    };

    if (JobFilterIsOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
      setClosedByOutsideClick(false);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [JobFilterIsOpen]);

  const { t } = useTranslation();

  return (
    <>
    {JobFilterIsOpen && <div className="job-service-modal">
<div className="pr-rele-multiselect-row">
        {Object.keys(options).map((label) => (
          <MultiSelectComponent
            options={options || {}}
            key={label}
            label={label}
            selectedOptions={selectedOptions.filter(opt =>
              options[label].some(option => option.value === opt.value)
            )}
            setSelectedOptions={(newOptions) => handleLabelSelection(label, newOptions)}
          />

        ))}
        <button className="pr-release-apply-button" onClick={handleApplyClick}>
          {t("Apply")}
        </button>
      </div>
</div>}
   
   
    </>
  );
}

export default JobFilterPopup;
