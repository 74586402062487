import TYPES from "../constants";

const initialState = {
  vendorFollowCount: {},
  error: null,
  loading: false,
  dataError:null,
  dataLoading:false,
  vendorFollowData: [],
  poData:{},
  penaltyData:{},
  penaltyLoading:false,
  acceptPenaltyLoading: false,
  acceptPenaltyError: null,  
  rejectedData:{},
  rejectPenaltyLoading: false,  
  rejectPenaltyError: null,
};

const VendorFollowReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.VENDOR_FOLLOW_COUNT_SUCCESS:
      return {
        ...state,
        vendorFollowCount: action.payload.data,
        error: null,
        loading: false,
      };
    case TYPES.VENDOR_FOLLOW_ALL_PR_DATA_SUCCESS:
      return {
        ...state,
        vendorFollowData: action.payload,
        dataError: null,
        dataLoading: false,
      }
    case TYPES.VENDOR_FOLLOW_PO_BY_ID_SUCCESS:
      return {
        ...state,
        poData: action.payload,
        error: null,
        loading: false,
      }
    case TYPES.VENDOR_FOLLOW_PENALTY_BY_ID_SUCCESS:
      return {
        ...state,
        penaltyData: action.payload,
        error: null,
        loading: false,
      }
      case TYPES.VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_SUCCESS:
      return {
        ...state,
          
        acceptPenaltyLoading: false,
        acceptPenaltyError: null,
      };
    case TYPES.VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_SUCCESS:
      return {
        ...state,
        rejectedData:action.payload,
        rejectPenaltyLoading: false,
        rejectPenaltyError:null,
      }
      case TYPES.VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_LOADING:
        return {
          ...state,
          rejectPenaltyLoading: true, 
          rejectPenaltyError: null
        }
      case TYPES.VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_FAILURE:
        return{
          ...state,
          rejectPenaltyLoading:false,
          rejectPenaltyError:action.error,
        }
      case TYPES.VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_FAILURE:
      return {
        ...state,
        acceptPenaltyLoading: false,
        acceptPenaltyError: action.error,
      };
    case TYPES.VENDOR_FOLLOW_COUNT_FAILURE:
    case TYPES.VENDOR_FOLLOW_PO_BY_ID_FAILURE:
    case TYPES.VENDOR_FOLLOW_PENALTY_BY_ID_FAILURE:
      return {
        ...state,
        error: action?.error,
        loading: false,
      };
    case TYPES.VENDOR_FOLLOW_ALL_PR_DATA_FAILURE:
      return {
        ...state,
        dataError: action?.error,
        dataLoading: false,
      }
    case TYPES.VENDOR_FOLLOW_COUNT_LOADING:
   
      return {
        ...state,
        loading: true,
      };
      case TYPES.VENDOR_FOLLOW_PENALTY_BY_ID_LOADING:
        return {
          ...state,
          penaltyLoading: true,
        };   
      case TYPES.VENDOR_FOLLOW_ALL_PR_DATA_LOADING:
        return{
          ...state,
          dataLoading: true, 
        }
        case TYPES.VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_LOADING:
          return {
            ...state,
            acceptPenaltyLoading: true,
            acceptPenaltyError: null,
          };
    case TYPES.VENDOR_FOLLOW_CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default VendorFollowReducer;
