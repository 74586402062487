import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import "./VendorProfile.css";
import { useLocation } from "react-router-dom";
import editLogo from "../../Assets/Images/editLogo.png";
import General from "../../Components/VendorProfile/VpGeneral";
import Financial from "../../Components/VendorProfile/VpFinancial";
import Commercial from "../../Components/VendorProfile/VpCommercial";
import Supplier from "../../Components/VendorProfile/VpSupplier";
import Hse from "../../Components/VendorProfile/VpHse";
import Categories from "../../Components/VendorProfile/VpCategories";
import VpDocuments from "../../Components/VendorProfile/VpDocuments";
import DashBoardLayout from "./../../Layouts/DashboardLayout/DashBoardLayout";
import PageLoader from "../../Models/Pageloader/PageLoader";
import { useSelector } from "react-redux";

const VendorProfile = () => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [type, setType] = useState(queryParams.get("type") || "General");
  const [editMode, setEditMode] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [docEdit, setDocEdit] = useState(false);
  const [commercialRegType, getCommercialRegType] = useState("");
  const [paymentMethod, getPaymentMethod] = useState("");
  const [generalInfoData, getGeneralInfoData] = useState({});

  const { userInfo } = useSelector((state) => state.UserInfoReducer);

  useEffect(() => {
    window.scrollTo(0, 0);
    queryParams.set("type", type);
    const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }, [type, queryParams]);
  const handleTypeSelect = (selectedKey) => {
    if (!editMode) {
      setType(selectedKey);
    }
  };

  const handleChangeEdit = () => {
    setEditMode(true);
  };

  return (
    <DashBoardLayout>
      {pageLoading && <PageLoader />}
      <section className="vp-main">
        <div className="um-title-btn vp-title-btn">
          <h2 className="um-title">{t("Vendor Profile")}</h2>
          <button
            onClick={handleChangeEdit}
            className="vendor-profile-edit"
            disabled={editMode}
          >
            {" "}
            <img src={editLogo} alt="" />
            <p className="edit"> {t("Edit")}</p>
          </button>
        </div>
        <Nav activeKey="" onSelect={handleTypeSelect} className="cv-navbar">
          <Nav.Item>
            <Nav.Link
              className={
                type === "General"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey={"General"}
            >
              <p
                className={
                  type === "General"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("General Info")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "categories"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey={"categories"}
            >
              <p
                className={
                  type === "categories"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Bidding Categories")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Financial"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Financial"
            >
              <p
                className={
                  type === "Financial"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {t("FinancialData/Health")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Commercial"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Commercial"
            >
              <p
                className={
                  type === "Commercial"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Commercial evaluation")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Supplier"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Supplier"
            >
              <p
                className={
                  type === "Supplier"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Supplier’s Quality")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "HSE"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="HSE"
            >
              <p
                className={
                  type === "HSE"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Suppliers’s HSE Management")}
              </p>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              className={
                type === "Documents"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Documents"
            >
              <p
                className={
                  type === "Documents"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                {t("Documents")}
              </p>
            </Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link
              className={
                type === "Summary"
                  ? "create-vendor-nav-active"
                  : "create-vendor-nav"
              }
              eventKey="Summary"
            >
              <p
                className={
                  type === "Summary"
                    ? "create-vendor-text-active"
                    : "create-vendor-text"
                }
              >
                {" "}
                Summary
              </p>
            </Nav.Link>
          </Nav.Item> */}
        </Nav>
        {type === "General" && (
          <General
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            setDocEdit={setDocEdit}
            getCommercialRegType={getCommercialRegType}
            getGeneralInfoData={getGeneralInfoData}
            setPageLoading={setPageLoading}
          />
        )}
        {type === "categories" && (
          <Categories
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            setPageLoading={setPageLoading}
          />
        )}
        {type === "Financial" && (
          <Financial
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            getPaymentMethod={getPaymentMethod}
            setDocEdit={setDocEdit}
            setPageLoading={setPageLoading}
          />
        )}
        {type === "Commercial" && (
          <Commercial
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            setPageLoading={setPageLoading}
          />
        )}
        {type === "Supplier" && (
          <Supplier
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            setPageLoading={setPageLoading}
          />
        )}
        {type === "HSE" && (
          <Hse
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            setPageLoading={setPageLoading}
            data={userInfo}
          />
        )}
        {type === "Documents" && (
          <VpDocuments
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
            setPageLoading={setPageLoading}
            data={userInfo}
            setDocEdit={setDocEdit}
            docEdit={docEdit}
            commercialRegistrationType={commercialRegType}
            paymentMethod={paymentMethod}
            generalInfoData={generalInfoData}
          />
        )}
        {/* {type === "Summary" && (
          <VpSummary
            setType={setType}
            setEditMode={setEditMode}
            editMode={editMode}
          />
        )} */}
      </section>
    </DashBoardLayout>
  );
};

export default VendorProfile;
