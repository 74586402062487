import React from "react";
import "./prcard.css";
import { useNavigate } from "react-router-dom";

const PrCard = ({ title, more, count, view, path }) => {
  const navigate = useNavigate();

  const onClickPoTable = () => {
    navigate(path);
  };
  return (
    <>
      <div className="pr-card p-3">
        <div className="d-flex justify-content-between align-items-center">
          <p className="pr-card-title">{title}</p>
          <p>{more}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="pr-card-count">{count}</h1>
          <a className="pr-card-view" style={{ cursor: "pointer" }}>
            {view}
          </a>
        </div>
      </div>
    </>
  );
};

export default PrCard;
