import React from "react";
import "./statcard.css";
import { useNavigate } from "react-router-dom";

const StateCard = ({ title, more, count, view, path }) => {
  const navigate = useNavigate();

  const onClickPoTable = () => {
    navigate(path);
  };
  return (
    <>
      <div className="stat-card p-3">
        <div className="d-flex justify-content-between align-items-center">
          <p className="stat-card-title">{title}</p>
          <p>{more}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="stat-card-count">{count}</h1>
          <a
            onClick={onClickPoTable}
            className="stat-card-view"
            style={{ cursor: "pointer" }}
          >
            {view}
          </a>
        </div>
      </div>
    </>
  );
};

export default StateCard;
