import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <footer className='reset-footer '>
            <p className='pt-1'>
                2022 AGC Vinythai Co.,Ltd All Right Reserved.
            </p>
            <p className='pt-1'>
                Privacy Policy Terms & Conditions
            </p>
        </footer>
    )
}

export default Footer