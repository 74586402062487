import React from "react";
import "./afterSubmit.css";
import { useNavigate } from "react-router-dom";
import {
        updatedDataFirst,
        updatedDataFifth,
        updatedDataSecond,
        updatedDataFourth,
        updatedDataThird,
        updatedDataSixth,
} from "../../Store/Reducer/registrationReducer";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const After = ({show, handleClose}) => {

        let navigate = useNavigate();
        const dispatch = useDispatch();
        
        const onClickOk = () => {
                navigate("/");
                dispatch(updatedDataFirst({}));
                dispatch(updatedDataSecond({}));
                dispatch(updatedDataThird({}));
                dispatch(updatedDataFourth({}));
                dispatch(updatedDataFifth({}));
                dispatch(updatedDataSixth({}));
        };
        const { t } = useTranslation();
        return (

                <>
                        <Modal dialogClassName="success-popup" show={show} onHide={handleClose} backdrop="static" style={{marginTop:"50px"}}>

                                <div className="submit-logo" >
                                        <img
                                                className="submit-img"
                                                src={require("../../Constants/images/submitLogo.png")}
                                                alt=""
                                        />
                                </div>
                                <h1 className="submit-done">{t("Done!")}</h1>
                                <h5 className="submit-text my-3">{t("Vendor Registration successfully")}</h5>
                                <div className="final-button">
                                        <input
                                                className="button-ok"
                                                type="submit"
                                                onClick={onClickOk}
                                                value={t("Ok")}
                                        />
                                </div>
                        </Modal>
                </>

        );
};

export default After;
