// vendor side Actions 

const Actions = {
    VENDOR_INFO : 'VENDOR_INFO',
    VENDOR_INFO_FAILURE:"VENDOR_INFO_FAILURE",
    UPDATE_VENDOR_INFO : 'UPDATE_VENDOR_INFO',
    UPDATE_VENDOR_INFO_LOADING: 'UPDATE_VENDOR_INFO_LOADING',
    UPDATE_VENDOR_INFO_FAILURE : 'UPDATE_VENDOR_INFO_FAILURE',

    // Vendor Follow
    VENDOR_FOLLOW_COUNT_LOADING : "VENDOR_FOLLOW_COUNT_LOADING",
    VENDOR_FOLLOW_COUNT_FAILURE : "VENDOR_FOLLOW_COUNT_FAILURE",
    VENDOR_FOLLOW_COUNT_SUCCESS : "VENDOR_FOLLOW_COUNT_SUCCESS",

    VENDOR_FOLLOW_ALL_PR_DATA_LOADING: "VENDOR_FOLLOW_ALL_PR_DATA_LOADING",
    VENDOR_FOLLOW_ALL_PR_DATA_FAILURE: "VENDOR_FOLLOW_ALL_PR_DATA_FAILURE",
    VENDOR_FOLLOW_ALL_PR_DATA_SUCCESS: "VENDOR_FOLLOW_ALL_PR_DATA_SUCCESS",

        //Vendor PO by Id
        VENDOR_FOLLOW_PO_BY_ID_SUCCESS:"VENDOR_DASHBOARD_PO_BY_ID_SUCCESS",
        VENDOR_FOLLOW_PO_BY_ID_FAILURE:"VENDOR_DASHBOARD_PO_BY_ID_FAILURE",
        VENDOR_FOLLOW_PO_BY_ID_LOADING:"VENDOR_DASHBOARD_PO_BY_ID_LOADING",
    VENDOR_FOLLOW_CLEAR : "VENDOR_FOLLOW_CLEAR",

    //Vendor Follow penalty by id
    VENDOR_FOLLOW_PENALTY_BY_ID_SUCCESS:"VENDOR_FOLLOW_PENALTY_BY_ID_SUCCESS",
    VENDOR_FOLLOW_PENALTY_BY_ID_FAILURE:"VENDOR_FOLLOW_PENALTY_BY_ID_FAILURE",
    VENDOR_FOLLOW_PENALTY_BY_ID_LOADING:"VENDOR_FOLLOW_PENALTY_BY_ID_LOADING",

    //Vendor Follow accept penalty by id
    VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_SUCCESS:"VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_SUCCESS",
    VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_FAILURE:"VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_FAILURE",
    VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_LOADING:"VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_LOADING",

    //Vendor Follow Reject Penalty by Id
    VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_LOADING:"VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_LOADING",
    VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_SUCCESS:"VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_SUCCESS",
    VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_FAILURE:"VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_FAILURE",

    LATEST_VENDOR_INFO: 'LATEST_VENDOR_INFO',
    CLEAR_VENDOR_INFO: 'CLEAR_VENDOR_INFO',
    CLEAR_LATEST_VENOR_INFO: 'CLEAR_LATEST_VENOR__INFO',

    //Vendor Dashboard PR DATA
    VENDOR_DASHBOARD_PRDATA_SUCCESS:"VENDOR_DASHBOARD_PRDATA_SUCCESS",
    VENDOR_DASHBOARD_PRDATA_FAILURE:"VENDOR_DASHBOARD_PRDATA_FAILURE",
    VENDOR_DASHBOARD_PRDATA_LOADING: "VENDOR_DASHBOARD_PRDATA_LOADING",

    //Vendor Dashboard PO DATA
    VENDOR_DASHBOARD_PODATA_SUCCESS:"VENDOR_DASHBOARD_PODATA_SUCCESS",
    VENDOR_DASHBOARD_PODATA_FAILURE:"VENDOR_DASHBOARD_PODATA_FAILURE",
    VENDOR_DASHBOARD_PODATA_LOADING: "VENDOR_DASHBOARD_PODATA_LOADING",
 

    //Vendor Dashboard Stats
    VENDOR_DASHBOARD_STATS_SUCCESS:"VENDOR_DASHBOARD_STATS_SUCCESS",
    VENDOR_DASHBOARD_STATS_FAILURE:"VENDOR_DASHBOARD_STATS_FAILURE",
    VENDOR_DASHBOARD_STATS_LOADING: "VENDOR_DASHBOARD_STATS_LOADING",


    //Vendor Dashboard PR LIST
    VENDOR_DASHBOARD_PR_LIST_SUCCESS:"VENDOR_DASHBOARD_PR_LIST_SUCCESS",
    VENDOR_DASHBOARD_PR_LIST_FAILURE:"VENDOR_DASHBOARD_PR_LIST_FAILURE",
    VENDOR_DASHBOARD_PR_LIST_LOADING: "VENDOR_DASHBOARD_PR_LIST_LOADING",

    //Vendor Dashboard PO LIST
    VENDOR_DASHBOARD_PO_LIST_SUCCESS:"VENDOR_DASHBOARD_PO_LIST_SUCCESS",
    VENDOR_DASHBOARD_PO_LIST_FAILURE:"VENDOR_DASHBOARD_PO_LIST_FAILURE",
    VENDOR_DASHBOARD_PO_LIST_LOADING: "VENDOR_DASHBOARD_PO_LIST_LOADING",
   

    //Vendor Dashboard PR STATS
    VENDOR_DASHBOARD_PR_STATS_SUCCESS:"VENDOR_DASHBOARD_PR_STATS_SUCCESS",
    VENDOR_DASHBOARD_PR_STATS_FAILURE:"VENDOR_DASHBOARD_PR_STATS_FAILURE",
    VENDOR_DASHBOARD_PR_STATS_LOADING: "VENDOR_DASHBOARD_PR_STATS_LOADING",

    //Vendor Dashboard PO STATS
    VENDOR_DASHBOARD_PO_STATS_SUCCESS:"VENDOR_DASHBOARD_PO_STATS_SUCCESS",
    VENDOR_DASHBOARD_PO_STATS_FAILURE:"VENDOR_DASHBOARD_PO_STATS_FAILURE",
    VENDOR_DASHBOARD_PO_STATS_LOADING: "VENDOR_DASHBOARD_PO_STATS_LOADING",


    //Vendor Dashboard PR ATTACHMENT
    VENDOR_DASHBOARD_PR_ATTACHMENT_SUCCESS:"VENDOR_DASHBOARD_PR_ATTACHMENT_SUCCESS",
    VENDOR_DASHBOARD_PR_ATTACHMENT_FAILURE:"VENDOR_DASHBOARD_PR_ATTACHMENT_FAILURE",
    VENDOR_DASHBOARD_PR_ATTACHMENT_LOADING: "VENDOR_DASHBOARD_PR_ATTACHMENT_LOADING",


    //Vendor Dashboard PR Quotation
    VENDOR_DASHBOARD_PR_QUOTATION_SUCCESS:"VENDOR_DASHBOARD_PR_QUOTATION_SUCCESS",
    VENDOR_DASHBOARD_PR_QUOTATION_FAILURE:"VENDOR_DASHBOARD_PR_QUOTATION_FAILURE",
    VENDOR_DASHBOARD_PR_QUOTATION_LOADING:"VENDOR_DASHBOARD_PR_QUOTATION_LOADING",
    


    //Vendor Dashboard PR Create Quotation
    VENDOR_DASHBOARD_PR_CREATE_QUOTATION_SUCCESS:"VENDOR_DASHBOARD_PR_CREATE_QUOTATION_SUCCESS",
    VENDOR_DASHBOARD_PR_CREATE_QUOTATION_FAILURE:"VENDOR_DASHBOARD_PR_CREATE_QUOTATION_FAILURE",
    VENDOR_DASHBOARD_PR_CREATE_QUOTATION_LOADING:"VENDOR_DASHBOARD_PR_CREATE_QUOTATION_LOADING",

    //Vendor Dashboard PR Quotation Delete
    VENDOR_DASHBOARD_PR_QUOTATION_DELETE_SUCCESS:"VENDOR_DASHBOARD_PR_QUOTATION_DELETE_SUCCESS",
    VENDOR_DASHBOARD_PR_QUOTATION_DELETE_FAILURE:"VENDOR_DASHBOARD_PR_QUOTATION_DELETE_FAILURE",
    VENDOR_DASHBOARD_PR_QUOTATION_DELETE_LOADING:"VENDOR_DASHBOARD_PR_QUOTATION_DELETE_LOADING",

    //Vendor Dashboard Deleted PO Doc
    VENDOR_DASHBOARD_DELETE_PO_DOC_LOADING:"VENDOR_DASHBOARD_DELETE_PO_DOC_LOADING",
    VENDOR_DASHBOARD_DELETE_PO_DOC_SUCCESS:"VENDOR_DASHBOARD_DELETE_PO_DOC_SUCCESS",
    VENDOR_DASHBOARD_DELETE_PO_DOC_FAILURE:"VENDOR_DASHBOARD_DELETE_PO_DOC_FAILURE",

    //Vendor Dashboard Add Quotation
    VENDOR_DASHBOARD_PR_ADD_QUOTATION_SUCCESS:"VENDOR_DASHBOARD_PR_ADD_QUOTATION_SUCCESS",
    VENDOR_DASHBOARD_PR_ADD_QUOTATION_FAILURE:"VENDOR_DASHBOARD_PR_ADD_QUOTATION_FAILURE",
    VENDOR_DASHBOARD_PR_ADD_QUOTATION_LOADING:"VENDOR_DASHBOARD_PR_ADD_QUOTATION_LOADING",


    //Vendor Dashboard Upload PO Doc
    VENDOR_DASHBOARD_UPLOAD_PO_DOC_SUCCESS:"VENDOR_DASHBOARD_UPLOAD_PO_DOC_SUCCESS",
    VENDOR_DASHBOARD_UPLOAD_PO_DOC_FAILURE:"VENDOR_DASHBOARD_UPLOAD_PO_DOC_FAILURE",
    VENDOR_DASHBOARD_UPLOAD_PO_DOC_LOADING:"VENDOR_DASHBOARD_UPLOAD_PO_DOC_LOADING",

    //Vendor Dashboard PO Doc
    VENDOR_DASHBOARD_PO_DOC_SUCCESS:"VENDOR_DASHBOARD_PO_DOC_SUCCESS",
    VENDOR_DASHBOARD_PO_DOC_FAILURE:"VENDOR_DASHBOARD_PO_DOC_FAILURE",
    VENDOR_DASHBOARD_PO_DOC_LOADING:"VENDOR_DASHBOARD_PO_DOC_LOADING",

    VENDOR_DASHBOARD_PRDATA_CLEAR:"VENDOR_DASHBOARD_PRDATA_CLEAR",

    //Update PO Status
    VENDOR_DASHBOARD_UPDATE_PO_STATUS_LOADING: "VENDOR_DASHBOARD_UPDATE_PO_STATUS_LOADING",
    VENDOR_DASHBOARD_UPDATE_PO_STATUS_SUCCESS:"VENDOR_DASHBOARD_UPDATE_PO_STATUS_SUCCESS",
    VENDOR_DASHBOARD_UPDATE_PO_STATUS_FAILURE:"VENDOR_DASHBOARD_UPDATE_PO_STATUS_FAILURE",

    //JOBS PR
    //PR COUNT
    GET_PR_COUNT_SUCCESS:"GET_PR_COUNT_SUCCESS",
    GET_PR_COUNT_FAILURE:"GET_PR_COUNT_FAILURE",
    GET_PR_COUNT_LOADING:"GET_PR_COUNT_LOADING",

    //PR LIST
    GET_ALL_PR_LIST_SUCCESS:"GET_ALL_PR_LIST_SUCCESS",
    GET_ALL_PR_LIST_FAILURE:"GET_ALL_PR_LIST_FAILURE",
    GET_ALL_PR_LIST_LOADING:"GET_ALL_PR_LIST_LOADING",

    //PR FILTER
    GET_PR_FILTER_SUCCESS:"GET_PR_FILTER_SUCCESS",
    GET_PR_FILTER_FAILURE:"GET_PR_FILTER_FAILURE",
    GET_PR_FILTER_LOADING:"GET_PR_FILTER_LOADING",

    //Single PR
    VENDOR_DASHBOARD_SINGLE_PR_SUCCESS:"VENDOR_DASHBOARD_SINGLE_PR_SUCCESS",
    VENDOR_DASHBOARD_SINGLE_PR_FAILURE:"VENDOR_DASHBOARD_SINGLE_PR_FAILURE",
    VENDOR_DASHBOARD_SINGLE_PR_LOADING:"VENDOR_DASHBOARD_SINGLE_PR_LOADING",

  }


module.exports = Actions;