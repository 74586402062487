import React from "react";
import "./StepProccessingBar.css";
import { useTranslation } from "react-i18next";
//inactive icon
import inactive_categories from "../../../Constants/images/inActiveIcons/inactiveCategories.png";
import inactive_Financial_Data from "../../../Constants/images/inActiveIcons/inactiveFinancialDataHealth.png";
import inactive_Supplier_Quantitative from "../../../Constants/images/inActiveIcons/inactiveSupplierQuantitative.png";
import inactive_Supplier_Quality from "../../../Constants/images/inActiveIcons/inactiveSupplierQuality.png";
import inactive_Suppliers_HSE_Management from "../../../Constants/images/inActiveIcons/inactiveSuppliersHseManagement.png";
import inactive_Documents from "../../../Constants/images/inActiveIcons/inactiveDocuments.png";
//active icon
import active_categories from "../../../Constants/images/activeIcons/activeCategories.png";
import active_Financial_Data from "../../../Constants/images/activeIcons/activeFinancialDataHealth.png";
import active_Supplier_Quantitative from "../../../Constants/images/activeIcons/activeSupplierQuantitative.png";
import active_Supplier_Quality from "../../../Constants/images/activeIcons/activeSupplierQuality.png";
import active_Suppliers_HSE_Management from "../../../Constants/images/activeIcons/activeSuppliersHseManagement.png";
import active_Documents from "../../../Constants/images/activeIcons/activeDocuments.png";
import active_Generalinfo from "../../../Constants/images/activeIcons/activeGeneralnfo.png";
//completed icon

import completed_categories from "../../../Constants/images/completedIcons/completedCategories.png";
import completed_Financial_Data from "../../../Constants/images/completedIcons/completedFinancialDataHealth.png";
import completed_Supplier_Quantitative from "../../../Constants/images/completedIcons/completedSupplierQuantitative.png";
import completed_Supplier_Quality from "../../../Constants/images/completedIcons/completedSupplierQuality.png";
import completed_Suppliers_HSE_Management from "../../../Constants/images/completedIcons/completedSuppliersHseManagement.png";
import completed_Generalinfo from "../../../Constants/images/completedIcons/completedGeneralnfo.png";

const StepProccessingBar = (props) => {
  const { t } = useTranslation();
  const config = {
    screen1: props.screen1,
    screen2: props.screen2,
    screen3: props.screen3,
    screen4: props.screen4,
    screen5: props.screen5,
    screen6: props.screen6,
    screen7: props.screen7,
  };
  return (
    <div>
      <div className="stepper-wrapper">
        <div
          className={
            config.screen1 === "completed"
              ? "stepper-item completed"
              : "stepper-item active"
          }
        >
          <div className="step-counter active">
            <img
              src={
                config.screen1 === "completed"
                  ? completed_Generalinfo
                  : active_Generalinfo
              }
              alt=""
            ></img>
          </div>
          <div
            className={
              config.screen1 === "active"
                ? "step-name active"
                : config.screen1 === "completed"
                ? "step-name active"
                : "step-name"
            }
          >
            {t("General Info")}
          </div>
        </div>
        <div
          className={
            config.screen2 === "active"
              ? "stepper-item active"
              : config.screen2 === "completed"
              ? "stepper-item completed"
              : "stepper-item"
          }
        >
          <div className="step-counter">
            <img
              src={
                config.screen2 === "active"
                  ? active_categories
                  : config.screen2 === "completed"
                  ? completed_categories
                  : inactive_categories
              }
              alt=""
            ></img>
          </div>
          <div
            className={
              config.screen2 === "active"
                ? "step-name active"
                : config.screen2 === "completed"
                ? "step-name active"
                : "step-name"
            }
          >
            {t("Categories")}
          </div>
        </div>
        <div
          className={
            config.screen3 === "active"
              ? "stepper-item active"
              : config.screen3 === "completed"
              ? "stepper-item completed"
              : "stepper-item"
          }
        >
          <div className="step-counter">
            <img
              src={
                config.screen3 === "inactive"
                  ? inactive_Financial_Data
                  : config.screen3 === "completed"
                  ? completed_Financial_Data
                  : active_Financial_Data
              }
              alt=""
            ></img>
          </div>
          <div
            className={
              config.screen3 === "active"
                ? "step-name active"
                : config.screen3 === "completed"
                ? "step-name active"
                : "step-name"
            }
          >
            {t("Financial Data / Health")}
          </div>
        </div>
        <div
          className={
            config.screen4 === "active"
              ? "stepper-item active"
              : config.screen4 === "completed"
              ? "stepper-item completed"
              : "stepper-item"
          }
        >
          <div className="step-counter">
            <img
              src={
                config.screen4 === "inactive"
                  ? inactive_Supplier_Quantitative
                  : config.screen4 === "completed"
                  ? completed_Supplier_Quantitative
                  : active_Supplier_Quantitative
              }
              alt=""
            ></img>
          </div>
          <div
            className={
              config.screen4 === "active"
                ? "step-name active"
                : config.screen4 === "completed"
                ? "step-name active"
                : "step-name"
            }
          >
            {t("Commercial evaluation")}
          </div>
        </div>
        <div
          className={
            config.screen5 === "active"
              ? "stepper-item active"
              : config.screen5 === "completed"
              ? "stepper-item completed"
              : "stepper-item"
          }
        >
          <div className="step-counter">
            <img
              src={
                config.screen5 === "inactive"
                  ? inactive_Supplier_Quality
                  : config.screen5 === "completed"
                  ? completed_Supplier_Quality
                  : active_Supplier_Quality
              }
              alt=""
            ></img>
          </div>
          <div
            className={
              config.screen5 === "active"
                ? "step-name active"
                : config.screen5 === "completed"
                ? "step-name active"
                : "step-name"
            }
          >
            {t("Supplier’s Quality")}
          </div>
        </div>
        <div
          className={
            config.screen6 === "active"
              ? "stepper-item active"
              : config.screen6 === "completed"
              ? "stepper-item completed"
              : "stepper-item"
          }
        >
          <div className="step-counter">
            <img
              src={
                config.screen6 === "inactive"
                  ? inactive_Suppliers_HSE_Management
                  : config.screen6 === "completed"
                  ? completed_Suppliers_HSE_Management
                  : active_Suppliers_HSE_Management
              }
              alt=""
            ></img>
          </div>
          <div
            className={
              config.screen6 === "active"
                ? "step-name active"
                : config.screen6 === "completed"
                ? "step-name active"
                : "step-name"
            }
          >
            {t("Suppliers’sHSEManagement")}
          </div>
        </div>
        <div
          className={
            config.screen7 === "active"
              ? "stepper-item active"
              : config.screen7 === "completed"
              ? "stepper-item completed"
              : "stepper-item"
          }
        >
          <div className="step-counter">
            <img
              src={
                config.screen7 === "inactive"
                  ? inactive_Documents
                  : active_Documents
              }
              alt=""
            ></img>
          </div>
          <div
            className={
              config.screen7 === "active"
                ? "step-name active"
                : config.screen7 === "completed"
                ? "step-name active"
                : "step-name"
            }
          >
            {t("Documents")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepProccessingBar;
