import TYPES from "../constants";

const initialState = {
  userInfo: {},
  error: null,
  loading: false,
  generalVendorInfo: {},
};

const UserInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.VENDOR_INFO:
      return {
        ...state,
        userInfo: action.payload,
        error: null,
      };

    case TYPES.UPDATE_VENDOR_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload,
        },
        error: null,
        loading: false,
      };
    case TYPES.UPDATE_VENDOR_INFO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case TYPES.UPDATE_VENDOR_INFO_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TYPES.LATEST_VENDOR_INFO:
      return {
        ...state,
        generalVendorInfo: action.payload,
      };
    case TYPES.CLEAR_LATEST_VENOR_INFO:
      return {
        ...state,
        generalVendorInfo: action.payload,
      };
    case TYPES.CLEAR_VENDOR_INFO:
      return initialState;

    default:
      return state;
  }
};

export default UserInfoReducer;
