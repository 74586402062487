import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
// import Profile from "../../profile";
import Announce from "../../registrationTopNav";
import StepProccessingBar from "../StepProccessingBar";
import Header from "../../../Components/header";
import { useDispatch, useSelector } from "react-redux";
import { updatedDataFifth } from "../../../Store/Reducer/registrationReducer";
import { handleKeyDown } from "../../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import dropDownIcon from "../../../Assets/Svg/drop-down-icon.svg";

const SuppliersQuality = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const vendorData = useSelector((state) => state.registration.fifthObject);

  const onClickBack = () => {
    navigate("/supplier");
  };
  const initialSelected =
    vendorData?.company_certified_to_recognize_center?.length > 0
      ? vendorData?.company_certified_to_recognize_center?.map((value) => ({
          label: value,
          value,
        }))
      : [];
  const [selected, setSelected] = useState(initialSelected);
  const [isNaSelected, setIsNaSelected] = useState(false);
  const [isOtherFieldSelected, setOtherFieldSelected] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const ArrowRenderer = ({ expanded }) => (
    <>
      {expanded ? (
        <img src={dropDownIcon} alt="" />
      ) : (
        <img src={dropDownIcon} alt="" />
      )}
    </>
  );

  const options = [
    { label: "ISO 9001", value: "ISO 9001", disabled: isNaSelected },
    { label: "ISO 14001", value: "ISO 14001", disabled: isNaSelected },
    { label: "TS 16949", value: "TS 16949", disabled: isNaSelected },
    {
      label: "HALAL ISO standard",
      value: "HALAL ISO standard",
      disabled: isNaSelected,
    },
    { label: "ISO 45001", value: "ISO 45001", disabled: isNaSelected },
    { label: "IEC 17025*", value: "IEC 17025*", disabled: isNaSelected },
    { label: "ASME U STAMP", value: "ASME U STAMP", disabled: isNaSelected },
    { label: "API", value: "API", disabled: isNaSelected },
    {
      label: "Other…. Please specify",
      value: "Other…. Please specify",
      disabled: isNaSelected,
    },
    { label: "N/A", value: "N/A", disabled: isOtherFieldSelected },
  ];

  useEffect(() => {
    if (vendorData?.company_certified_to_recognize_center?.includes("N/A")) {
      setIsNaSelected(true);
      setOtherFieldSelected(false);
    } else if (
      Array.isArray(vendorData?.company_certified_to_recognize_center) &&
      vendorData?.company_certified_to_recognize_center.length > 0
    ) {
      setIsNaSelected(false);
      setOtherFieldSelected(true);
    } else {
      setIsNaSelected(false);
      setOtherFieldSelected(false);
    }
  }, [vendorData.company_certified_to_recognize_center]);

  const onChangerRecognizedStandard = (selectedValues) => {
    setSelected(selectedValues);

    const valuesArray = selectedValues?.map((item) => item.value);

    if (valuesArray?.includes("N/A")) {
      setIsNaSelected(true);
      setOtherFieldSelected(false);
    } else if (valuesArray?.length == 0) {
      setIsNaSelected(false);
      setOtherFieldSelected(false);
    } else {
      setOtherFieldSelected(true);
      setIsNaSelected(false);
    }
  };
  const onSubmit = (data) => {
    if (selected.length === 0) {
      toast.error(
        "Please select at least one option. in Quality Management System certified to a recognized standard"
      );
      return;
    }

    if (selected.some((option) => option.value === "Other…. Please specify")) {
      if (!data.certified_specify) {
        toast.error("Please specify the certification.");
        return;
      }
    }
    // Include selected values in the data being dispatched
    data.company_certified_to_recognize_center = selected.map(
      (option) => option.value
    );
    dispatch(updatedDataFifth(data));
    navigate("/supplierhse");
  };

  useEffect(() => {
    Object.entries(vendorData).forEach(([name, value]) => {
      setValue(name, value);
    });
  }, [vendorData]);
  return (
    <>
      {/* <Profile/> */}
      <div className="regstration-header">
        <Header />
      </div>
      <div className="announce-navbar">
        <Announce />
      </div>
      <div className="main-container">
        <StepProccessingBar
          screen1="completed"
          screen2="completed"
          screen3="completed"
          screen4="completed"
          screen5="active"
          screen6="inactive"
          screen7="inactive"
        />
        <div className="vendor-page-container">
          <h1 className="header-registration">{t("Vendor Registration")}</h1>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown}>
          <div className="form-container-with-bottomspace-finanacial">
            <h4 className="header-registration-two">
              Supplier’s Quality Management System
              (ระบบการจัดการคุณภาพของซัพพลายเออร์)
            </h4>

            <div className="textbox-container">
              <div className="left-container">
                <label className="vendor-input-label">
                  {t(
                    "Is your company's Quality Management System certified to a recognized standard?"
                  )}
                  <span className="staricon">*</span>
                </label>
                <br />

                <div className="dropdown-multi">
                  {/* <MultiSelect
                    options={options}
                    value={selected}
                    onChange={onChangerRecognizedStandard}
                    labelledBy="Select"
                    hasSelectAll={false}
                    name="company_certified_to_recognize_center"
                    ArrowRenderer={ArrowRenderer}
                  /> */}

                  <Controller
                    name="company_certified_to_recognize_center"
                    control={control}
                    rules={{ required: t("Please select the correct value") }}
                    render={({ field, fieldState }) => (
                      <>
                        <MultiSelect
                          className="mb-3"
                          options={options}
                          value={selected}
                          onChange={(selected) => {
                            onChangerRecognizedStandard(selected);
                            field.onChange(selected);
                          }}
                          labelledBy="Select"
                          hasSelectAll={false}
                          ArrowRenderer={ArrowRenderer}
                        />
                        {fieldState.error && (
                          <span className="error-msg mt-3">
                            {fieldState.error.message}
                          </span>
                        )}
                      </>
                    )}
                  />

                  {/* {errors.company_certified_to_recognize_center && (
                    <span className="error-msg">
                      {t("Please select the correct value")}{" "}
                    </span>
                  )} */}
                </div>
              </div>
              <div className="right-container">
                <label className="vendor-input-label">
                  {t(
                    "For Laboratory Accreditation, please specify the equipment, calibration item, range and best uncertainty?"
                  )}{" "}<span className="staricon">*</span>
                </label>
                <br />

                <select
                  type="text"
                  className="input-border registration"
                  {...register("laboratory_accreditation", {
                    required: true,
                  })}
                >
                  {" "}
                  <option value="" hidden>
                    {" "}
                    {t("select")}
                  </option>
                  <option value="Yes">{t("Yes")}</option>
                  <option value="No">{t("No")}</option>
                </select>
                {errors.laboratory_accreditation && (
                  <span className="error-msg">
                    {t("Please select the correct value")}{" "}
                  </span>
                )}
              </div>
            </div>
            <div className="textbox-container">
              {selected?.some(
                (option) => option.value === "Other…. Please specify"
              ) && (
                <div className="left-container-hidden-input">
                  <br />

                  <input
                    type="text"
                    className="input-border registration-left "
                    {...register("certified_specify", {
                      required: true,
                    })}
                  />

                  {errors.certified_specify && (
                    <span className="error-msg">
                      {t("Please enter other specific data")}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="buttons">
            <div className="button-submit-container-back">
              <input
                className="button-submit-back"
                type="submit"
                value={t("Back")}
                onClick={onClickBack}
              />
            </div>
            <div className="button-submit-container">
              <button className="button-submit" type="submit">
                {t("Next")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SuppliersQuality;
