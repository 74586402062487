import TYPES from "../constants";

const initialState = {
  prCount: {},
  error: null,
  loading: false,
  prList:{},
  filterList:{},
  singlePr:{},
};

const VendorJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_PR_COUNT_LOADING:
    case TYPES.GET_ALL_PR_LIST_LOADING:
    case TYPES.GET_PR_FILTER_LOADING:
    case TYPES.VENDOR_DASHBOARD_SINGLE_PR_LOADING:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case TYPES.GET_PR_COUNT_SUCCESS:
      return {
        ...state,
        prCount: action.payload,
        error: null,
        loading: false,
      };
    case TYPES.GET_ALL_PR_LIST_SUCCESS:
      return{
        ...state,
        prList: action.payload,
        error: null,
        loading: false,
      }
    case TYPES.GET_PR_FILTER_SUCCESS:
      return{
        ...state,
        filterList:action.payload,
        error:null,
        loading:false,

      }
      case TYPES.VENDOR_DASHBOARD_SINGLE_PR_SUCCESS:
        return{
          ...state,
          singlePr:action.payload,
          error:null,
          loading:false,
  
        }
    case TYPES.GET_PR_COUNT_FAILURE:
    case TYPES.GET_ALL_PR_LIST_FAILURE:
    case TYPES.GET_PR_FILTER_FAILURE:
    case TYPES.VENDOR_DASHBOARD_SINGLE_PR_FAILURE:
      return {
        ...state,
        error: action?.error,
        loading: false,
      }
    case TYPES.VENDOR_FOLLOW_CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default VendorJobsReducer;
