import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PoTable from "../../Components/PoTable";
import PoCard from "../../Components/PoTable/PoCard";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import "./potable.css";
import PageLoader from "../../Models/Pageloader/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import { fetchPoStatus } from "../../Store/Actions/vendor_dashboard";

const PoTablePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const { dashboardPOStats: poStatusData, statsLoading: poStatusLoading, statsError: poStatusError } = useSelector((state) => state.VendorDashboardReducer);

  useEffect(() => {
    dispatch(fetchPoStatus());
  }, [dispatch]);


  const getCount = (index) => {
    return poStatusData?.data && poStatusData?.data[index] ? poStatusData?.data[index]?.count : "0";
  };

  return (
    <DashBoardLayout>
      {(poStatusLoading)&& <PageLoader />}
      <div className="main-dashboard-body mb-3">
        <h3 className="vendor-dashboard-heading py-2 px-3">{t("PO")}</h3>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-lg-4 col-md-6 py-3">
              <PoCard
                title={t("All")}
                more={"..."}
                count={poStatusLoading
                  ? 0
                  : poStatusError
                  ? 0
                  : (parseInt(getCount(0), 10) + parseInt(getCount(1), 10)).toString()}
              />
            </div>
            <div className="col-lg-4 col-md-6 py-3">
              <PoCard
                title={t("Confirming PO")}
                more={"..."}
                count={poStatusLoading
                  ? 0
                  : poStatusError
                  ? 0
                  : getCount(0)}
              />
            </div>
            <div className="col-lg-4 col-md-6 py-3">
              <PoCard
                title={t("Acknowledge")}
                more={"..."}
                count={poStatusLoading
                  ? 0
                  : poStatusError
                  ? 0
                  : getCount(1)}
              />
            </div>
          </div>
        </div>

        <div className="container-fluid mt-2 mb-5">
        {poStatusError ? (
            <div className="error-message">
              <p>{t("Error loading PO data")}</p>
            </div>
          ) : (
            <PoTable poStatusData={poStatusData || {}} />
            
          )}

        </div>
      </div>
    </DashBoardLayout>
  );
};

export default PoTablePage;
