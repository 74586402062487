import TYPES from "../constants";

const initialState = {
  statsLoading: false,
  loading: false,
  poLoading: false,
  attachmentLoading: false,
  quotationLoading:false,
  dashboardStats: {},
  dashboardPRList: {},
  dashboardPOList: {},
  dashboardPRStats: {},
  dashboardPOStats: {},
  dashboardPRData: {},
  dashboardPOData: {},
  attachments: [],
  quotations:[],
  newQuotation:{},
  poDoc:[],
  quotationError:null,
  attachmentError: null,
  statsError: null,
  error: null,
  poError: null,
};

const VendorDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.VENDOR_DASHBOARD_STATS_LOADING:
    case TYPES.VENDOR_DASHBOARD_PO_STATS_LOADING:
    case TYPES.VENDOR_DASHBOARD_PR_STATS_LOADING:
      return {
        ...state,
        statsLoading: true,
        statsError: null,  
      };
      
    case TYPES.VENDOR_DASHBOARD_PO_LIST_LOADING:
    case TYPES.VENDOR_DASHBOARD_PODATA_LOADING:
      return {
        ...state,
        poLoading: true,
        poError: null, 
      };
      
    case TYPES.VENDOR_DASHBOARD_PR_LIST_LOADING:
    case TYPES.VENDOR_DASHBOARD_PRDATA_LOADING:
    case TYPES.VENDOR_DASHBOARD_DELETE_PO_DOC_LOADING:
    case TYPES.VENDOR_DASHBOARD_UPDATE_PO_STATUS_LOADING:
      return {
        ...state,
        loading: true,
        error: null, 
      };
    case TYPES.VENDOR_DASHBOARD_PR_ATTACHMENT_LOADING:
    case TYPES.VENDOR_DASHBOARD_UPLOAD_PO_DOC_LOADING:
        return {
          ...state,
          attachmentLoading: true,
          attachmentError: null,
        }; 
    case TYPES.VENDOR_DASHBOARD_PR_QUOTATION_LOADING:
      case TYPES.VENDOR_DASHBOARD_PR_ADD_QUOTATION_LOADING:
      case TYPES.VENDOR_DASHBOARD_PR_CREATE_QUOTATION_LOADING:
        case TYPES.VENDOR_DASHBOARD_PR_QUOTATION_DELETE_LOADING:
      return{
        ...state,
        quotationLoading: true,
        quotationError: null,
      }
    case TYPES.VENDOR_DASHBOARD_UPDATE_PO_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error:null,
      }
     case TYPES.VENDOR_DASHBOARD_UPLOAD_PO_DOC_SUCCESS:
      return {
        ...state,
        poDoc: [...state.poDoc, ...(Array.isArray(action.payload) ? action.payload.filter(item => item !== undefined) : [action.payload].filter(item => item !== undefined))],
        attachmentError:null,
        attachmentLoading:false,
      }
      case TYPES.VENDOR_DASHBOARD_PR_QUOTATION_DELETE_SUCCESS:
      return {
        ...state,
        quotationLoading: false,
        quotations: state.quotations.filter(
          (q) => q.QtAttachmentId !== action.payload.QtAttachmentId
        ),
        quotationError: null,
      };
    case TYPES.VENDOR_DASHBOARD_STATS_SUCCESS:
      return {
        ...state,
        statsLoading: false,
        dashboardStats: action.payload,
        statsError: null,  
      };
    
    case TYPES.VENDOR_DASHBOARD_PR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardPRList: action.payload,
        error: null, 
      };
      
      case TYPES.VENDOR_DASHBOARD_PO_DOC_SUCCESS:
      return {
        ...state,
        poDoc: action.payload,
      };

    case TYPES.VENDOR_DASHBOARD_PO_LIST_SUCCESS:
      return {
        ...state,
        poLoading: false,
        dashboardPOList: action.payload,
        poError: null,  
      };
      
    case TYPES.VENDOR_DASHBOARD_PR_STATS_SUCCESS:
      return {
        ...state,
        statsLoading: false,
        dashboardPRStats: action.payload,
        statsError: null,  
      };
      
    case TYPES.VENDOR_DASHBOARD_PO_STATS_SUCCESS:
      return {
        ...state,
        statsLoading: false,
        dashboardPOStats: action.payload,
        statsError: null,  
      };
      
    case TYPES.VENDOR_DASHBOARD_PRDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardPRData: action.payload,
        error: null,  
      };
      
    case TYPES.VENDOR_DASHBOARD_PODATA_SUCCESS:
      return {
        ...state,
        poLoading: false,
        dashboardPOData: action.payload,
        poError: null, 
      };
    case TYPES.VENDOR_DASHBOARD_PR_ATTACHMENT_SUCCESS:
        return {
          ...state,
          attachmentLoading: false,
          attachments: action.payload,
          attachmentError: null,
        };
    case TYPES.VENDOR_DASHBOARD_PR_QUOTATION_SUCCESS:
          return{
            ...state,
            quotationLoading:false,
            quotations: action.payload || [],
            quotationError: null,
          }

          case TYPES.VENDOR_DASHBOARD_PR_CREATE_QUOTATION_SUCCESS: 
          return {
            ...state,
            quotationLoading: false,
            quotations: [...state.quotations, ...(Array.isArray(action.payload) ? action.payload.filter(item => item !== undefined) : [action.payload].filter(item => item !== undefined))],
            quotationError: null,
          };
          case TYPES.VENDOR_DASHBOARD_PR_ADD_QUOTATION_SUCCESS:
      return {
        ...state,
        quotationLoading: false,

        quotations: Array.isArray(action.payload)
          ? [
              ...state.quotations,
              ...action.payload.filter(item => item !== undefined && item !== null)
            ]
          : state.quotations,
        quotationError: null,
      };
    case TYPES.VENDOR_DASHBOARD_DELETE_PO_DOC_SUCCESS:
      return{
        ...state,
        error:null,
        loading:false,
        poDoc: state.poDoc.filter(
          (q) => q.attachmentId !== action.payload
        ),
      }
    case TYPES.VENDOR_DASHBOARD_STATS_FAILURE:
    case TYPES.VENDOR_DASHBOARD_PR_STATS_FAILURE:
    case TYPES.VENDOR_DASHBOARD_PO_STATS_FAILURE:
      return {
        ...state,
        statsLoading: false,
        statsError: action.error,  
      };
      
    case TYPES.VENDOR_DASHBOARD_PR_LIST_FAILURE:
    case TYPES.VENDOR_DASHBOARD_PRDATA_FAILURE:
    case TYPES.VENDOR_DASHBOARD_PODATA_FAILURE:
    case TYPES.VENDOR_DASHBOARD_DELETE_PO_DOC_FAILURE:
    case TYPES.VENDOR_DASHBOARD_UPDATE_PO_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,  
      };
      
    case TYPES.VENDOR_DASHBOARD_PO_LIST_FAILURE:
      return {
        ...state,
        poLoading: false,
        poError: action.error,  
      };
    case TYPES.VENDOR_DASHBOARD_PR_ATTACHMENT_FAILURE:
    case TYPES.VENDOR_DASHBOARD_UPLOAD_PO_DOC_FAILURE:
        return {
          ...state,
          attachmentLoading: false,
          attachmentError: action.error,
        };
    case TYPES.VENDOR_DASHBOARD_PR_QUOTATION_FAILURE:
      return {
        ...state,
        quotationLoading: false,
        quotationError: action.error,
      };
   
    case TYPES.VENDOR_DASHBOARD_PR_CREATE_QUOTATION_FAILURE: 
    case TYPES.VENDOR_DASHBOARD_PR_QUOTATION_DELETE_FAILURE:
      case TYPES.VENDOR_DASHBOARD_PR_ADD_QUOTATION_FAILURE:
    return {
      ...state,
      quotationLoading: false,
      quotationError: action.error,
    };
    case TYPES.VENDOR_FOLLOW_CLEAR:
      return initialState;
    
    default:
      return state;
  }
};

export default VendorDashboardReducer;
