export const COUNTRY_LIST = "statecity-common/Get-Country";
export const STATE_LIST = "statecity-common/Get-State-By-Country?country_id=";
export const CITY_LIST = "statecity-common/Get-City-By-Country-State?state_id=";
export const VENDOR_REGISTER = "vendor/Vendor-Registration";
export const CHECK_TAX_NO = "vendor/check-tax-Registration?registration_no=";

// Vendor side login API endpoint

export const VENDOR_LOGIN = "vendor/Vendor-login";
export const VENDOR_RESET_PASSWORD = "vendor/Vendor-Forgot-P-I";
export const VENDOR_RESET_PASSWORD_UPDATE = "vendor/Vendor-Password-Update";
export const VENDOR_RESET_PASSWORD_VERIFY = "vendor/Vendor-Password-Otp-Verify";
export const VENDOR_RESEND_OTP = "vendor/Vendor-Resend-Otp";
export const VENDOR_UPDATE_DETAILS = "vendor/Vendor-Update";
export const VENDOR_DELETE_DOC = "vendor/Vendor-Delete-Document";
export const VENDOR_DETAILS = "vendor/Vendor-Details";


// press release api
export const GET_ALL_PRESS = "press/Get-All-Press-public";


// Vendor Dashboard API endpoint
export const GET_VENDOR_DASHBOARD_STATS = "VendorDashboard/Vendor-Get-Stats-Info";
export const GET_VENDOR_DASHBOARD_PR_LIST = "VendorDashboard/Vendor-Get-Pr-Data";
export const GET_VENDOR_DASHBOARD_SINGLE_PR= "VendorDashboard/Get-Single-Pr-Job-Details";
export const GET_VENDOR_DASHBOARD_PO_DATA="VendorDashboard/Vendor-Get-Po-Data";
export const GET_VENDOR_DASHBOARD_PR_DATA="VendorDashboard/Vendor-Get-Pr-Data";
export const GET_VENDOR_DASHBOARD_PR_DATA_BY_FILTER="VendorDashboard/Vendor-Get-Pr-Data-By-Filter";
export const GET_VENDOR_DASHBOARD_PO_DATA_BY_FILTER="VendorDashboard/Vendor-Get-Po-Data-With-Filter";
export const GET_VENDOR_DASHBOARD_PR_STATS="VendorDashboard/Vendor-Get-Pr-Status-Count";
export const GET_VENDOR_DASHBOARD_PO_STATS="VendorDashboard/Vendor-Get-Po-Status-Count";
export const GET_VENDOR_DASHBOARD_PR_JOB_ATTACHMENT="VendorDashboard/Get-Pr-Job-All-AttachmentList-By-Job";
export const GET_VENDOR_DASHBOARD_QUOTATION="VendorDashboard/Get-Quotations";
export const POST_VENDOR_DASHBOARD_UPLOAD_PO_DOC="VendorDashboard/Vendor-Upload-Po-Doc";
export const POST_VENDOR_DASHBOARD_CREATE_QUOTATION="VendorDashboard/Create-JobQuotations";
export const POST_VENDOR_DASHBOARD_ADDMORE_QUOTATION="VendorDashboard/AddMore-JobQuotations-Attachment";
export const PATCH_VENDOR_DASHBOARD_UPDATE_PO_STATUS="VendorDashboard/Vendor-Update-Po-Status-ById";
export const DELETE_VENDOR_DASHBOARD_PO_DOC="VendorDashboard/Vendor-Delete-Po-Doc-ById";
export const DELETE_VENDOR_DASHBOARD_QUOATATION_ATTACHMENT= "VendorDashboard/Delete-JobQuotations-Attachment";


// Vendor Follow
export const GET_VENDOR_FOLLOW_STATUS_COUNT = "VendorFollow/Vendor-Get-All-Pr-Status-Count";
export const GET_VENDOR_FOLLOW_ALL_PR_DATA="VendorFollow/Vendor-Get-All-PrData";
export const GET_PENALTY_BY_ID="Penalty/Penalty-Get-ById"
export const ACCEPT_PENALTY_BY_ID="Penalty/Penalty-Vendor-Accept"
export const REJECT_PENALTY_BY_ID="Penalty/Penalty-Vendor-Reject"

//Purchase Order
export const GET_PO_BY_ID= "PurchaseOrder/Get-Po-ById"

//Vendor Jobs
export const GET_PR_COUNT="VendorDashboard/Get-Pr-Count-By-PrGroup"
export const GET_PR_LIST_OPEN="VendorDashboard/Get-All-Pr-List-Open"
export const GET_PR_FILTER_DROPDOWN="PrService/Get-Pr-Groups-List-For-Filter-DropDown"
