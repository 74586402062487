import React, { useEffect } from "react";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import "./dashboardhome.css";
import StatCard from "../../Components/StatCard/Index";
import DashBoardMainPagePoTable from "../../Components/DashboardMainPagePoTable";
import DashBoardMainPagePrTable from "../../Components/DashboardMainPagePrTable";
import ImagePdf from "../../Assets/Images/Vector.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPoList,
  fetchPrList,
  fetchVendorStats,
} from "../../Store/Actions/vendor_dashboard.js";
import PageLoader from "../../Models/Pageloader/PageLoader.jsx";
import { formatNumber } from "../../Utils/Utils.js";
const DashBoardHomePage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const {
    dashboardStats: statsData,
    statsLoading,
    statsError,
    loading: prLoading,
    error: prError,
    poLoading,
    poError,
  } = useSelector((state) => state.VendorDashboardReducer || {});
  
   
  
  useEffect(() => {
    dispatch(fetchVendorStats());
  }, []);

 
  return (
    <>
      {/* {statsLoading && <PageLoader/>} */}
      {(prLoading || statsLoading || poLoading) && <PageLoader />}
      <DashBoardLayout>
        <div className="main-dashboard-body mb-3">
          <h1 className="vendor-dashboard-heading py-2 px-2">
            {t("Vendor DashBoard")}
          </h1>
          <div className="container-fluid mt-2">
            <div className="row">
              <div className="col-lg-4 col-md-6 py-3">
                <StatCard
                  title={t("Performance")}
                  more={"..."}
                  count={
                    statsLoading
                      ? 0
                      : statsError
                      ? 0
                      : formatNumber(statsData?.evaluation_score) || 0
                  }
                  view={""}
                />
              </div>
              <div className="col-lg-4 col-md-6 py-3">
                <StatCard
                  title={"PR"}
                  more={"..."}
                  count={
                    statsLoading
                      ? 0
                      : statsError
                      ? 0
                      : statsData?.prCount || 0
                  }
                  view={"view More"}
                  path={"/pr-table"}
                />
              </div>
              <div className="col-lg-4 col-md-6 py-3">
                <StatCard
                  title={"PO"}
                  more={"..."}
                  count={
                    statsLoading
                      ? 0
                      : statsError
                      ? 0
                      : statsData?.poCount || 0
                  }
                  view={"view More"}
                  path={"/po-table"}
                />
              </div>
            </div>
          </div>

          {/* pr table div */}
          <div className="container-fluid mt-2 mb-5">
            <DashBoardMainPagePrTable
              error={prError}
              loading={prLoading}
            />
          </div>

          {/* po table div */}
          <div className="container-fluid mt-4 mb-5">
            <DashBoardMainPagePoTable
              error={poError}
              loading={poLoading}
            />
          </div>
        </div>
      </DashBoardLayout>
    </>
  );
};

export default DashBoardHomePage;
