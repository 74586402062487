import React, { useEffect, useState } from "react";
import "./uploadpo.css";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import { useNavigate, useParams } from "react-router-dom";
import UploadPO from "../../Components/UploadPo";
import { useDispatch, useSelector } from "react-redux";
import { fetchPoList, updateVendorStatus, uploadPoDoc } from "../../Store/Actions/vendor_dashboard";

const UpLoadPoPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [myFiles, setMyFiles] = useState([]);
  const [newAttachedFiles,setNewAttachedFiles]=useState([]);
  const onClickDashboard = async() => {
   await newAttachedFiles.forEach((file)=>{
      dispatch(uploadPoDoc(id,file))
    })
    dispatch(updateVendorStatus(id))
    dispatch(fetchPoList())
    navigate("/dashboard");
  };
  const {poDoc} = useSelector(state => state.VendorDashboardReducer);
  
  const onClickCancel=()=>{
    navigate("/po-table")
  }
  useEffect(() => {
    if (poDoc.length>0) {
      setMyFiles(poDoc.map(attachment => ({
        id:attachment?.attachmentId || "",
        path: attachment?.attachmentName || "",
        url: attachment?.attachmentUrl || "",
        
      })));
    }
  }, [poDoc]);


  return (
    <DashBoardLayout>
      <div className="container-fluid">
        <div className="main-dashboard-body upload-po-body">
          <div className="upload-po-main-page my-5">
            <h1 className="upload-po-main-heading mx-3 pt-2">
              Upload po document file
            </h1>
            <p className="upload-po-main-para mx-3">
              maximum file size 1000KB. Supported file formats: jpeg jpg png
            </p>
            <UploadPO id={id} myFiles={myFiles} setMyFiles={setMyFiles} data={poDoc} newAttachedFiles={newAttachedFiles} setNewAttachedFiles={setNewAttachedFiles}/>
          </div>
          <div className="conatiner d-flex justify-content-end align-items-center">
            <button onClick={onClickCancel}  className="upload-po-cancel-btn px-4 mx-3">Cancel</button>
            <button
              className="upload-po-upload-btn px-4"
              onClick={onClickDashboard}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </DashBoardLayout>
  );
};

export default UpLoadPoPage;
