import React from "react";

const CancelAndUpdateBtn = ({
  editMode,
  setEditMode,
  reset,
  setDocEdit,
  docEdit,
  extractedValue,
  paymentMethodUpdated,
  commercialTypeCheck,
}) => {
  const onClickCancel = () => {
    //     reset();
    setEditMode(false);
    if (docEdit) {
      setDocEdit(false);
    }
  };
  return (
    <>
      {editMode && (
        <div className="my-5 d-flex justify-content-end ">
          <button
            type="button"
            className="um-create-btn mx-4"
            onClick={onClickCancel}
          >
            Cancel
          </button>
          <button type="submit" className="um-submit-btn">
            {(commercialTypeCheck &&
              ["L", "T", "P"].includes(extractedValue)) ||
            paymentMethodUpdated
              ? "Next"
              : "Update"}
          </button>
        </div>
      )}
    </>
  );
};

export default CancelAndUpdateBtn;
