import React, { useEffect } from "react";
import PrTable from "../../Components/PrTable";
import PrCard from "../../Components/PrTable/PrCard";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import "./prtable.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {  fetchPrStatus } from "../../Store/Actions/vendor_dashboard";
import PageLoader from "../../Models/Pageloader/PageLoader";

const PrTablePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  

  const { dashboardPRStats:prStatusData, statsLoading:prStatusLoading, error:prStatusError } = useSelector((state) => state.VendorDashboardReducer);

  useEffect(() => {
   
      dispatch(fetchPrStatus());
    
  }, [dispatch])
  
  return (
    <DashBoardLayout>
       {prStatusLoading && <PageLoader/>}
      <div className="main-dashboard-body mb-3">
        <h3 className="vendor-dashboard-heading py-2 px-3">{t("PR")}</h3>
        <div className="container-fluid mt-2">
          <div className="row">
            <div className="col-lg-4 col-md-6 py-3">
              <PrCard title={t("All PR")} more={"..."} count={prStatusLoading ? 0 : prStatusError ? 0 : prStatusData?.allCount ||0 } />
            </div>
            <div className="col-lg-4 col-md-6 py-3">
              <PrCard title={t("Bidding")} more={"..."} count={prStatusLoading ? 0 : prStatusError ? 0 : prStatusData?.biddingCount || 0} />
            </div>
            <div className="col-lg-4 col-md-6 py-3">
              <PrCard title={t("Awarded")} more={"..."} count={prStatusLoading ? 0 : prStatusError ? 0 : prStatusData?.awardedCount ||0}  />
            </div>
          </div>
        </div>

        <div className="container-fluid mt-2 mb-5">
          <PrTable  prStatusData={prStatusData}/>
        </div>
      </div>
    </DashBoardLayout>
  );
};

export default PrTablePage;
