import React, { useState } from "react";
import JobsTable from "../../Components/Jobs/JobsTable";
import Login from "../../Components/Login/Login";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import Announce from "../registrationTopNav";
import "./jobs.css";
import Header from "../../Components/header";
import { useTranslation } from "react-i18next";

const AnnouncementsJobs = () => {
  const token = localStorage.getItem("vms_vendor_token");
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");


  // onChange event handler to the search input
  const handleSearchInputChange = (event) => {
      setSearchQuery(event.target.value);
  };

  return (
    <>
      {!token ? (
        <div>
          <DefaultLayout>
            <div className="loginpage">
              <Login />
            </div>
          </DefaultLayout>
        </div>
      ) : (
        <div className="resetcontentpage">
          <Header />
        </div>
      )}
      <div className="">
        <div className="announce-navbar">
          <Announce />
        </div>
        <div className="announcement-page-background ">
          <div className="announcement-jobs-top">
            <h1 className="announcement-jobs-top-header py-2">
              {t("Announcements")}
            </h1>
            <input
              type="search"
              placeholder={t("Search Announcements")}
              className="announcement-jobs-top-search py-2 px-3"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
        </div>
      </div>

      <div className="jobs-body mb-5">
        <JobsTable searchQuery={searchQuery}/>
        
      </div>
    </>
  );
};

export default AnnouncementsJobs;
