import React from "react";
import "./newspage.css";
import agcN from "../../Assets/Svg/agcN.svg";

const NewsPage = ({ title, id, description, show_created_at, press_url }) => {
  // Parse  string into a JavaScript array
  const pressUrlArray = JSON.parse(press_url);

  // N access the URL at index 0
  const urlAtIndex0 = pressUrlArray.length > 0 ? pressUrlArray[0] : agcN;

  return (
    <>
      <div className="card-body">
        <div className="news-card-body p-3">
          <div className="news-card-image m-3">
            <img src={urlAtIndex0} alt="agc" />
          </div>
          <div className="mx-2 py-2 d-flex flex-column">
            <h6 className="news-card-top-heading-content py-2">{title}</h6>
            <p className="news-card-para-content">{description}</p>
            <p className="news-card-date">{show_created_at}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsPage;
