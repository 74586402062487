import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { COUNTRY_LIST } from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";

export const getAllCountryList = createAsyncThunk("CountryList", async () => {
        try {
                var requestOptions = {
                        method: "GET",
                        redirect: "follow",
                };
                const response = await fetch(`${BaseUrl}${COUNTRY_LIST}`, requestOptions);
                const result = await response.json();
                return result.data;
        } catch (error) {
                toast.error(error?.message || "Something went wrong!")
                throw error;
        }
});

export const myReducer = createSlice({
        name: "CountryList",
        initialState: {
                loading: false,
                CountryList: [],
        },
        reducers: {
                updateSelectCountryList: (state, action) => {
                        state.selectedCountryId = action.payload;
                },
        },
        extraReducers: {
                [getAllCountryList.pending]: (state) => {
                        state.loading = true;
                },
                [getAllCountryList.fulfilled]: (state, { payload }) => {
                        state.loading = false;
                        state.CountryList = payload;
                },
        },
});

export const {  updateSelectCountryList } = myReducer.actions;

export default myReducer.reducer;
