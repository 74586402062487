import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import {
  VENDOR_DETAILS,
  VENDOR_UPDATE_DETAILS,
} from "../../ApiEndPoints/ApiEndPoints";
import TYPES from "../constants";
import axios from "axios";

export const getVendorInfo = () => {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${VENDOR_DETAILS}`, {
        headers,
      });
      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_INFO,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: TYPES.VENDOR_INFO_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_INFO_FAILURE,
        error: error.message,
      });
    }
  };
};

export const updateVendorInfo = (updatedInfo) => {
  return async (dispatch, getState) => {
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: ` Bearer ${token}`,
      };

      dispatch({ type: TYPES.UPDATE_VENDOR_INFO_LOADING });

      const response = await axios.patch(
        `${BaseUrl}${VENDOR_UPDATE_DETAILS}`,
        updatedInfo,
        { headers }
      );
      
      if (response.data.status === 200) {
        dispatch({
          type: TYPES.UPDATE_VENDOR_INFO,
          payload: response.data,
        });
        // toast.success(response.message);
        return response;
      } else {
        // toast.error(response.message);
        dispatch({
          type: TYPES.UPDATE_VENDOR_INFO_FAILURE,
          payload: null,
          error: "API request failed",
        });
        return response;
      }
    } catch (error) {
      dispatch({
        type: TYPES.UPDATE_VENDOR_INFO,
        payload: null,
        error: error.message,
      });
      return error;
    }
  };
};

export const latestGeneralVendorInfo = (generalInfo) => {
  return {
    type: TYPES.LATEST_VENDOR_INFO,
    payload: generalInfo,
  };
};

export const clearLatestVendorInfo = () => {
  return {
    type: TYPES.CLEAR_LATEST_VENOR_INFO,
    payload: null,
  };
};

export const clearVendorInfo = () => {
  return {
    type: TYPES.CLEAR_VENDOR_INFO,
  };
};
