import React from "react";
import "./jobstable.css";

const SingleJobExpandedTable = ({ data }) => {
  
  return (
    data && (
      <div className="jobs-expanded-table-div">
        <table className="jobs-expanded-table">
          <thead>
            <tr>
              <th>
                <p style={{ visibility: "hidden" }}>Locationssss</p>
              </th>
              <th>Purchase Requisition</th>
              <th>Description</th>
              <th>Purchase Group</th>
              <th>Item</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>
                <p style={{ visibility: "hidden" }}>Locationssss</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              
                <tr >
                    <td></td>
                  <td>{data?.PR_ID || ""}</td>
                  <td>{data?.PR_description_Short || ""}</td>
                  <td>{data?.PR_Group || ""}</td>
                  <td>{1 || ""}</td>
                  <td>{data?.PR_QTY || ""}</td>
                  <td>{data?.PR_Unit || ""}</td>
                  <td></td>
                </tr>
            }
          </tbody>
        </table>
      </div>
    )
  );
};

export default SingleJobExpandedTable;
