import React from "react";
import DefaultLayout from "../../Layouts/DefaultLayout/DefaultLayout";
import CopyrightIcon from "@mui/icons-material/Copyright";
import "./resetPassword.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import { VENDOR_RESET_PASSWORD_UPDATE } from "../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ResetPasswordPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  // password matching
  const firstPassword = watch("password");
  const confirmPassword = watch("confirm_password");
  const bothPasswordsMatch = firstPassword !== confirmPassword;

  const onSubmit = (data) => {
    const token = localStorage.getItem("user_reset_token");
    // const username = localStorage.getItem('username')
    if (!bothPasswordsMatch) {
      axios
        .post(`${BaseUrl}${VENDOR_RESET_PASSWORD_UPDATE}`, data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            navigate("/");
          } else if (res.data.status === 401) {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err?.message || "Something went wrong!")
        });
    } else {
      toast.error("Please Match the password");
    }
  };

  const handleVendor = () => {
    navigate("/vendor-guide");
  };

  const handleJobs = () => {
    navigate("/jobs");
  };

  return (
    <DefaultLayout>
      <div className="loginpage">
        <div className="login">
          <div className="col-12 col-md-6 col-lg-6 d-none d-sm-block">
            <div className="login-page-left-card">
              {/* <img src="/images/VendorPortal.png" alt=""  className='blur-image'/> */}
              <p className="small">AGC VINYTHAI</p>
              <h1 className="main">
                VENDOR <br /> MANAGEMENT SYSTEM
              </h1>
              <div className="buttons pt-4">
                <button
                  type="button"
                  onClick={handleVendor}
                  className="vendor-btn "
                >
                  {t("Vendor Guide")}
                </button>
                <button
                  type="button"
                  onClick={handleJobs}
                  className="job-btn mx-2"
                >
                  {t("Jobs")}
                </button>
              </div>
              <div className="left-card-bottom">
                <div>
                  <CopyrightIcon style={{ width: "12px" }} />
                </div>{" "}
                &nbsp;
                <div>2022 AGC Vinythai. All Right Reserved</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6  col-sm-12">
            <div className="login-page-right-card">
              <h2 className="mt-4 pt-3 second-heading">
                {t("Reset Password")}
              </h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div class="mb-2" style={{ width: "280px" }}>
                  <label for="#" class="form-label">
                    <h6 className="small-heading">{t("New Password")}</h6>
                  </label>
                  <input
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      maxLength: {
                        value: 16,
                        message: "Password cannot exceed 16 characters",
                      },
                      pattern: {
                        value:
                          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
                        message:
                          "Password must contain at least one letter, one number, and can include special characters (@$!%*?&)",
                      },
                    })}
                    style={{ border: "2px solid #5B6770" }}
                    type="password"
                    class="form-control"
                  />
                  {errors.password && (
                    <span className="error-msg">{errors.password.message}</span>
                  )}
                </div>

                <div class="mb-2" style={{ width: "280px" }}>
                  <label for="#" class="form-label">
                    <h6 className="small-heading">{t("Confirm Password")}</h6>
                  </label>
                  <input
                    {...register("confirm_password", {
                      required:
                        "confirm password is required and its must match the password",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long",
                      },
                      maxLength: {
                        value: 16,
                        message: "Password cannot exceed 16 characters",
                      },
                      pattern: {
                        value:
                          /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
                        message:
                          "Password must contain at least one letter, one number, and can include special characters (@$!%*?&)",
                      },
                    })}
                    style={{ border: "2px solid #5B6770" }}
                    type="password"
                    class="form-control"
                  />
                  {errors.confirm_password && (
                    <span className="error-msg">
                      {errors.confirm_password.message}
                    </span>
                  )}
                </div>

                <div className="pt-2 mt-1 pb-2">
                  <button type="submit" className="loginbutton">
                    Reset Password
                  </button>
                </div>
              </form>
              <div
                className="right-card-bottom"
                style={{ marginTop: "auto", marginBottom: "18px" }}
              >
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  {" "}
                  <span> {t("Terms of Service")} | </span>
                </a>
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  {" "}
                  <span className="px-1"> {t("Privacy Policy")} | </span>{" "}
                </a>

                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  {" "}
                  <span className="px-1">AVT Privacy Policy | </span>{" "}
                </a>
                <a href="https://agcvinythai.com/pdpa/" target="blank">
                  <span className="px-1"> {t("Accessibility")} </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default ResetPasswordPage;
