import { useSelector } from "react-redux";

const useIsEditFieldMandatory = () => {
  const { userInfo } = useSelector((state) => state.UserInfoReducer);

  // from the userInfo object getting the commercial registration type

  const commercialRegType = userInfo && userInfo.commercial_reg_type;

  //     from the commercialRegType extracting 1st index value
  const extractedValue =
    commercialRegType && commercialRegType.toString().match(/\((.*?)\)/)?.[1];
  const isFieldMandatory = extractedValue === "L";

  return isFieldMandatory;
};

export default useIsEditFieldMandatory;
