import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import "./dashboardheader.css";
import Eng_flag from "../../Assets/Images/emojione_flag-for-united-states.svg";
import thaiFlag from "../../Assets/Svg/thaiFlag.svg";
import dropDownIcon from "../../Assets/Svg/drop-down-icon.svg";
// import AgcLogo from "../../Assets/Images/agc_logo.png";

const DashBoardheader = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const [flagDropdownOpen, setFlagDropdownOpen] = useState(false);
  const handleClick = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);
    setFlagDropdownOpen(false);
  };
  const handleChangeLanguage = () => {
    setFlagDropdownOpen(!flagDropdownOpen);
  };
  const userName = localStorage.getItem("userName") || "VendorName";
  const firstLetter = userName.charAt(0).toUpperCase(); 
  const userCompany=localStorage.getItem("userCompany") || "AGC Vinythai";
  const secretKey = "mysecretkey12345"; // Replace this with your actual secret key
  const decryptData = (encryptedData, key) => {
    try {
      const decodedData = decodeURIComponent(atob(encryptedData));
      const decryptedData = JSON.parse(decodedData);
      return decryptedData;
    } catch (error) {
      return null;
    }
  };

  // Retrieve and decrypt the data from localStorage
  const encryptedDataFromLocalStorage = localStorage.getItem("encryptedUser");
  const decryptedDataString = decryptData(
    encryptedDataFromLocalStorage,
    secretKey
  );

  // Parse the decrypted data into an object
  const decryptedData = decryptedDataString
    ? JSON.parse(decryptedDataString)
    : null;



  return (
    <div className="dashboard-header py-2">
      <div className="d-flex align-items-center pt-2 px-2">
        <p className="dashboard-vendor-circle">{firstLetter}</p>
        <div className="px-2">
          <h5 className="dashboard-vendor-name">{t(userName)}</h5>
          <p className="dashboard-vendor-sub-name">{t(userCompany)}</p>
        </div>
      </div>
      <div>
        <div
          // className="dropdown"
          style={{ cursor: "pointer", marginLeft: "30px" }}
        >
          {selectedLanguage == "thai" ? (
            <img src={thaiFlag} alt="thai" style={{ width: "35px" }} />
          ) : (
            <img src={Eng_flag} alt="us" style={{ width: "35px" }} />
          )}

          <img
            onClick={handleChangeLanguage}
            style={{
              marginLeft: "10px",
              paddingRight: "50px",
              cursor: "pointer",
            }}
            src={dropDownIcon}
            alt=""
          />
        </div>

        {flagDropdownOpen && (
          <div className="flag-dropdown">
            <div className="flag-img-div" onClick={() => handleClick("en")}>
              <img src={Eng_flag} alt="" />
              <p>English (United States)</p>
            </div>

            <div className="flag-img-div" onClick={() => handleClick("thai")}>
              <img src={thaiFlag} alt="" />
              <p>Thai</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashBoardheader;
