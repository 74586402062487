import { Grid } from "@mui/material";
import React from "react";
import SideNavBar from "../../Components/Sidebar";
import "./DashBoardlayout.css";
import DashBoardheader from "../../Components/DashboardHeader";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DashBoardLayout = ({ children }) => {
  return (
    <>

      <Col lg={2} className="d-none d-sm-block">
        <SideNavBar />
      </Col>
      <Col lg={12}>
        <DashBoardheader />
        {children}
      </Col>
    </>
  );
};

export default DashBoardLayout;
