import React from 'react'
import Header from '../../Components/header'

const DefaultLayout = ({ children }) => {
    return (
        <div className='mainpage'>
            <div className='pt-3'>
                <Header />
            </div>
            {children}
        </div>
    )
}

export default DefaultLayout