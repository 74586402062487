import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "./dashboardmainpageprtable.css";
import { useTranslation } from "react-i18next";
import JobExpandedTable from "../Jobs/JobsTable/JobExpandedTable";
import CustomPagination from "../CustomPagination/CustomPagination";
import CSVDownloadButton from "../CsvDownload/CsvDownload";
import PrDetailsUnderJobs from "../../Models/PrDetailsUnderJobs/PrDetailsUnderJobs";
import PageLoader from "../../Models/Pageloader/PageLoader";
import { DateGenerator } from "../../Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrList } from "../../Store/Actions/vendor_dashboard";

const customStyles = {
  headCells: {
    style: {
      backgroundColor: "#103A5D",
      color: "white",
      fontSize: "14px",
      fontFamily: "Sarabun",
      fontStyle: "normal",
      fontWeight: 400,
    },
  },
};

const ExpandedComponent = ({ data }) => (
  <pre>
    <JobExpandedTable data={data.JobPrDetails} />
  </pre>
);

const DashboardPrTable = ({error,loading}) => {
  const dispatch = useDispatch();
  const {
    dashboardPRList: prData} = useSelector((state) => state.VendorDashboardReducer || {});
  
   
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data,setData]=useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [count,setCount]=useState(0);
  useEffect(() => {
      dispatch(fetchPrList(currentPage,itemsPerPage));
  }, [currentPage,itemsPerPage]);
  useEffect(()=>{
    if(prData?.data){
      setData(prData?.data || [])
      setCount(prData?.TotalCount || 0)
    }
    
  },[prData])
  const columns = [
    {
      name: `${t("Location")}`,
      selector: (row) => row?.PR_Company || '', 
      sortable: true,
    },
    {
      name: `${t("PR No")}`,
      selector: (row) =>  (row?.JobPrDetails && row?.JobPrDetails?.length>0 ? (row?.JobPrDetails?.length > 1 ? `Combined (${row?.JobPrDetails?.length})` : row?.JobPrDetails[0]?.PrSapDetail?.PR_ID ) :"" ) || '', 
      sortable: true,
    },
    {
      name: `${t("PR Title")}`,
      selector: (row) => row?.PrTitle || '', 
      sortable: true,
      width: "10%",
    },
    {
      name: `${t("Purchase Group")}`,
      selector: (row) => (row?.JobPrDetails && row?.JobPrDetails?.length>0 ? (row?.JobPrDetails?.length > 1 ? `` : row?.JobPrDetails[0]?.PrSapDetail?.PR_Group ) :"" ) || '', 
      sortable: true,
      width: "15%",
    },
    {
      name: "Date Start",
      selector: (row) => DateGenerator(row?.BiddingDate) || '', 
      sortable: true,
    },
    {
      name: "Date End",
      selector: (row) => DateGenerator(row?.DeliveryDate) || '', 
      sortable: true,
    },
    {
      name: "Buyer",
      selector: (row) => row?.BuyerName || '', 
      sortable: true,
    },
    {
      id:"Status",
      name: `${t("Status")}`,
      cell: (row) => (
        <button
        className={`dashboard-main-page-award ${row?.jobVendorStatus===0?"status-bidding":row?.jobVendorStatus===1?"Awarded":row?.jobVendorStatus===2?"status-declined":""}`}
        >
          {row?.jobVendorStatus===0?"Bidding":row?.jobVendorStatus===1?"Awarded":row?.jobVendorStatus===2?"Declined":""} 
        </button>
      ),
    },
    {
      name: `${t("Action")}`,
      cell: (row) => (
        <button
          className="dashboard-main-page-pr-view"
          onClick={() => {
            handleViewPr(row);
          }}
        >
          View PR
        </button>
      ),
    },
  ];
  

  const handleViewPr = (result) => {
    setSelectedRowData(result); 
    setIsModalOpen(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDropdownChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    setCurrentPage(1);
  };
  const displayedRange = {
    start: (currentPage - 1) * itemsPerPage + 1,
    end: Math.min(currentPage * itemsPerPage, count),
    total: data.length,
  };

  const headerNames = columns.map((column) => column?.name || '');

  //code to map csvdata
  const csvData = (data || []).map((item) =>
    columns.reduce((row, column) => {
      if(column.id==="Status"){
        row[column.name]=item?.jobVendorStatus===0?"Bidding":item?.jobVendorStatus===1?"Awarded":item?.jobVendorStatus===2?"Declined":""
      }
      else{
        row[column.name] = column.selector ? column.selector(item) : item[column.name] || '';
      }
      return row;
    }, {})
  );
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
    {loading && <PageLoader />}
      {isModalOpen && (
        <PrDetailsUnderJobs
          isOpen={isModalOpen}
          closeModal={closeModal}
          formTitle="PR detail"
          data={selectedRowData}
        />
      )}
      <div className="dahsboard-main-page-tables">
        <div className="d-flex justify-content-between align-items-center p-3">
          <div>
            <h5 className="dahsboard-main-page-top-head-name">{t("PR")}</h5>
            <div className="">
              <label className="show-po-table-top" for="">
                {t("Show")}
              </label>
              <select
                className="show-select-options px-2 mx-2"
                id=""
                name=""
                value={itemsPerPage}
                onChange={handleDropdownChange}
              >
                {/* <option value="5">5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <label className="entries-po-table-top">{t("Entries")}</label>
            </div>
          </div>
          <div>
            <CSVDownloadButton
              data={csvData}
              headers={headerNames}
              filename="pr_table_data.csv"
            />
          </div>
        </div>
        <div className="pt-3">
          <DataTable
            className="vendor-dashboard-pr-table"
            columns={columns}
            data={data}
            customStyles={customStyles}
            pagination={false}
            noDataComponent="No records to display"
            expandableRows
            expandableRowDisabled={(row) => !row.JobPrDetails || row?.JobPrDetails?.length<2}
            expandableRowExpanded={(row) => row?.JobPrDetails?.length>1}
            expandableRowsComponent={ExpandedComponent}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="table-show-entries">
          {" "}
          Showing {displayedRange.start} to {displayedRange.end} of{" "}
          {count} entries
        </p>
        <CustomPagination
          totalItems={count ||0}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          innerPagination={true}
        />
      </div>
    </>
  );
};

export default DashboardPrTable;
