import React, { useState,  } from "react";
import "./VpFiles.css";
import cross from "../../../Assets/Svg/cross-icon.svg";
import axios from "axios";
import { BaseUrl } from "../../../ApiBaseUrl/ApiBaseUrl";
import { VENDOR_DELETE_DOC } from "../../../ApiEndPoints/ApiEndPoints";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { getVendorInfo } from "../../../Store/Actions/vendor_info";

const VpFiles = ({
        icon,
        label,
        editMode,
        setEditMode,
        fileCount,
        fileName,
        fileUrl,
        vendorId,
        setPageLoading,
        onFileUpload,
        mandotary,
        isDisable,
}) => {
        const dispatch = useDispatch();
        const [selectedFile, setSelectedFile] = useState(null);
        const Token = localStorage.getItem("vms_vendor_token");

        const handleDeleteDoc = () => {
                if (fileName) {
                        setPageLoading(true);
                        const data = {
                                file_name: fileName,
                        };

                        axios
                                .post(`${BaseUrl}${VENDOR_DELETE_DOC}`, data, {
                                        headers: { Authorization: `Bearer ${Token}` },
                                })
                                .then((res) => {
                                        if (res.data.status === 200) {
                                                toast.success(res.data.message);
                                                dispatch(getVendorInfo())
                                                setPageLoading(false);
                                        } else if (res.data.status === 401) {
                                                setPageLoading(false);
                                                toast.error("Something went wrong. Please try again.");
                                        }
                                })
                                .catch((err) => {
                                        setPageLoading(false);
                                        toast.error(err);
                                });
                } else {
                        setSelectedFile(null);
                        setPageLoading(false);
                }
        };
        const handleFileChange = (event) => {
                const file = event.target.files[0];
                if (file && file.size <= 20 * 1024 * 1024) {
                        setSelectedFile(file);
                        toast.success(
                                "file uploaded successfully please click on the update button to save changes"
                        );
                        onFileUpload(file);
                } else {
                        // File size exceeds 20MB limit
                        setSelectedFile(null);
                        toast.error("File size exceeds 20MB limit");
                }
        };


        function isFile(value) {
                return value instanceof File;
        }

        function isURL(value) {
                const urlPattern = /^(http[s]?|ftp):\/\/[^ "]+$/;
                return urlPattern.test(value);
        }

        return (
                <>
                        {(editMode || fileName || selectedFile) && (
                                <div className="vp-files-main">
                                        <label className="vp-label" style={{ whiteSpace: "nowrap" }}>
                                                {label} <span className="staricon"> {mandotary ? "*" : ""}</span>
                                        </label>
                                        <div className="d-flex justify-content-between align-items-center">
                                                <p className="vp-file-count">
                                                        {isURL(fileUrl) || isFile(selectedFile) ? fileCount : "0"}{" "}
                                                        Attachment
                                                </p>
                                                {!editMode && (
                                                        <label
                                                                className="vp-label"
                                                                style={{ display: "contents", cursor: "pointer" }}
                                                        >
                                                                <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                                        Download All
                                                                </a>
                                                        </label>
                                                )}
                                        </div>
                                        <div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                        {selectedFile !== null && isFile(selectedFile) ? (
                                                                <div className="vp-file-div">
                                                                        <img src={icon} alt="" />
                                                                        <div>
                                                                                <p>{selectedFile.name}</p>
                                                                                <span>
                                                                                        {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                                                                                </span>
                                                                        </div>
                                                                        {editMode && (
                                                                                <img
                                                                                        src={cross}
                                                                                        alt=""
                                                                                        className="cross-icon"
                                                                                        onClick={() => setSelectedFile(null)}
                                                                                />
                                                                        )}
                                                                </div>
                                                        ) : (
                                                                <>
                                                                        {fileUrl !== null && isURL(fileUrl) ? (
                                                                                <div className="vp-file-div">
                                                                                        <img src={icon} alt="" />
                                                                                        <div>
                                                                                                {fileUrl ? (
                                                                                                        <a
                                                                                                                href={fileUrl}
                                                                                                                target="_blank"
                                                                                                                rel="noopener noreferrer"
                                                                                                        >
                                                                                                                {fileName}
                                                                                                        </a>
                                                                                                ) : (
                                                                                                        <p>{fileName}</p>
                                                                                                )}
                                                                                        </div>
                                                                                        {editMode && !mandotary && (
                                                                                                <img
                                                                                                        src={cross}
                                                                                                        alt=""
                                                                                                        className="cross-icon"
                                                                                                        onClick={handleDeleteDoc}
                                                                                                />
                                                                                        )}
                                                                                </div>
                                                                        ) : (
                                                                                ""
                                                                        )}
                                                                </>
                                                        )}

                                                        {editMode && (!fileName || fileName === "" || mandotary) && (
                                                                <div className="button-attach-container-hse">
                                                                        <label
                                                                                className={
                                                                                        isDisable
                                                                                                ? "uploadFile-document-disabled"
                                                                                                : "uploadFile-document"
                                                                                }
                                                                        >
                                                                                Upload File
                                                                                <input
                                                                                        type="file"
                                                                                        onChange={isDisable ? "" : handleFileChange}
                                                                                        accept="application/pdf"
                                                                                        hidden
                                                                                        disabled={isDisable}
                                                                                />
                                                                        </label>
                                                                </div>
                                                        )}
                                                </div>
                                        </div>
                                </div>
                        )}
                </>
        );
};

export default VpFiles;
