import { BaseUrl } from "../../ApiBaseUrl/ApiBaseUrl";
import {
  ACCEPT_PENALTY_BY_ID,
  GET_PENALTY_BY_ID,
  GET_PO_BY_ID,
  GET_VENDOR_FOLLOW_ALL_PR_DATA,
    GET_VENDOR_FOLLOW_STATUS_COUNT,
    REJECT_PENALTY_BY_ID,
} from "../../ApiEndPoints/ApiEndPoints";
import TYPES from "../constants";
import axios from "axios";
import { toast } from "react-toastify";

export const getVendorFollowCountAction = () => {
  return async (dispatch) => {
    dispatch({
        type : TYPES.VENDOR_FOLLOW_COUNT_LOADING
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_VENDOR_FOLLOW_STATUS_COUNT}`, {
        headers,
      });
      
      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_COUNT_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_COUNT_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_FOLLOW_COUNT_FAILURE,
        error: error.message,
      });
    }
  };
};
export const getVendorFollowAllPRData = (page = 1, pageSize = 10, orderBy = 'PoPrDbId', orderDir = 'ASC',filterStatus) => {
  return async (dispatch) => {
    dispatch({
        type : TYPES.VENDOR_FOLLOW_ALL_PR_DATA_LOADING
    });
    try {
      
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const params = { page, pageSize, orderBy, orderDir };
      if (filterStatus !== undefined) {
        params.filterStatus = filterStatus;
      }
      const response = await axios.get(`${BaseUrl}${GET_VENDOR_FOLLOW_ALL_PR_DATA}`, {headers,
        params,
      });
     
      
      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_ALL_PR_DATA_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_ALL_PR_DATA_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_FOLLOW_ALL_PR_DATA_FAILURE,
        error: error.message,
      });
    }
  };
};


export const getVendorPoById = (id) => {
  return async (dispatch) => {
    dispatch({
        type : TYPES.VENDOR_FOLLOW_PO_BY_ID_LOADING
    });
    try {
      
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_PO_BY_ID}/${id}`, {
        headers,
      });
     
      
      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_PO_BY_ID_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_PO_BY_ID_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_FOLLOW_PO_BY_ID_FAILURE,
        error: error.message,
      });
    }
  };
};

export const getVendorPenaltyById = (id) => {
  return async (dispatch) => {
    dispatch({
        type : TYPES.VENDOR_FOLLOW_PENALTY_BY_ID_LOADING
    });
    try {
      
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(`${BaseUrl}${GET_PENALTY_BY_ID}/${id}`, {
        headers,
      });
     
      
      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_PENALTY_BY_ID_SUCCESS,
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_PENALTY_BY_ID_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      dispatch({
        type: TYPES.VENDOR_FOLLOW_PENALTY_BY_ID_FAILURE,
        error: error.message,
      });
    }
  };
};


export const acceptVendorPenalty = (id, files) => {
  return async (dispatch) => {
    dispatch({
        type : TYPES.VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_LOADING
    });
    try {
      
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const formData = new FormData();
      await files?.forEach((file)=>{
        formData.append('attachments', file);
      });

      const response = await axios.patch(`${BaseUrl}${ACCEPT_PENALTY_BY_ID}/${id}`,formData ,{
        headers
      });
     
      
      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_SUCCESS,
        });
        toast.success("Penalty Accepted Successfully")
      } else {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_FAILURE,
          error: "API request failed",
        });
      }
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch({
        type: TYPES.VENDOR_FOLLOW_ACCEPT_PENALTY_BY_ID_FAILURE,
        error: error.message,
      });
    }
  };
};

export const rejectVendorPenalty = (id, description, files) => {
  return async (dispatch) => {
    dispatch({
      type: TYPES.VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_LOADING,
    });
    try {
      const token = localStorage.getItem("vms_vendor_token");
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      };

      const formData = new FormData();
      formData.append('description', description);
      files.forEach((file) => {
        formData.append('attachments', file);
      });


      const response = await axios.patch(`${BaseUrl}${REJECT_PENALTY_BY_ID}/${id}`, formData, {
        headers,
      });
      if (response.status === 200) {
        dispatch({
          type: TYPES.VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_SUCCESS,
          payload: response.data,
        });
        toast.success("Penalty Rejected Successfully")
        
      } else {
        
        dispatch({
          type: TYPES.VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_FAILURE,
          error: "API request failed",
        });
       
      }
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch({
        type: TYPES.VENDOR_FOLLOW_REJECT_PENALTY_BY_ID_FAILURE,
        error: error.message,
      });
      
    }
  };
};




export const clearVendorInfo = () => {
  return {
    type: TYPES.VENDOR_FOLLOW_CLEAR,
  };
};
