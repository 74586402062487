import React, { useEffect } from "react";
import "./podetails.css";
import PoDetails from "../../Components/PoDetails";
import DashBoardLayout from "../../Layouts/DashboardLayout/DashBoardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVendorPoById } from "../../Store/Actions/vendorFollowAction";
import PageLoader from "../../Models/Pageloader/PageLoader";

const PoDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const onClickPoPage = () => {
    navigate("/po-table");
  };
  const dispatch = useDispatch();
  
  const { poData, loading, error } = useSelector((state) => state.VendorFollowReducer);

  useEffect(() => {
    dispatch(getVendorPoById(id));
  }, [dispatch,id]);
  
  return (
    <>
    {loading && <PageLoader />}
      <DashBoardLayout>
        <div className="main-dashboard-body" style={{ marginBottom: "100px" }}>
          <div className="container-fluid my-3">
            <PoDetails data={poData}/>
          </div>
          <div className="d-flex justify-content-end container-fluid my-3">
            <button
              className="po-details-back-btn px-4 py-1"
              onClick={onClickPoPage}
            >
              Back
            </button>
          </div>
        </div>
      </DashBoardLayout>
    </>
  );
};

export default PoDetailsPage;
