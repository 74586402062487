import React from "react";
// import SamplePdf from "./test.pdf";

const VendorGuide = (props) => {
  const { pdf } = props;
  const language=localStorage.getItem("i18nextLng");

  return (
    <>
      {/* <h1 className="d-flex align-items-center justify-content-center">
        PDF Not Available
      </h1> */}
      <div style={{ width: '100%', height: '100vh' }}>
      <iframe
        src={language==="thai"?"https://vinythai-assets.s3.ap-southeast-1.amazonaws.com/VMSmanual-Full-v1_thai.pdf":"https://vinythai-assets.s3.ap-southeast-1.amazonaws.com/VMSmanual_eng.pdf"}
        width="100%"
        height="100%"
        title="PDF Viewer"
        frameBorder="0"
      >
        This browser does not support PDFs. Please download the PDF to view it:  <a href={language === "thai" ?"https://vinythai-assets.s3.ap-southeast-1.amazonaws.com/VMSmanual-Full-v1_thai.pdf":"https://vinythai-assets.s3.ap-southeast-1.amazonaws.com/VMSmanual_eng.pdf"} download>Download PDF</a>
      </iframe>
    </div>
    </>
  );
};

export default VendorGuide;
