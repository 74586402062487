import React, { useState, useEffect } from "react";
import "./termsandconditions.css";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RiCloseCircleFill } from "react-icons/ri";

const TermsAndConditions = ({ show, handleClose }) => {
  const { register, watch, setValue } = useForm();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isAgreed, setIsAgreed] = useState(false);

  const watchFields = watch(["consent2", "consent4"]);
  const canBeSubmitted = () => {
    return checked ? setIsDisabled(true) : setIsDisabled(false);
  };

  const onCheckboxClick = () => {
    setChecked(!checked);
    return canBeSubmitted();
  };

  useEffect(() => {
    if (!watchFields) return;
    const checkIsAgreed = watchFields.every((consent) => consent);
    setIsAgreed(checkIsAgreed);
  }, [watchFields]);

  const onClickVendor = () => {
    navigate("/general-info");
  };

  // Handle checkbox click to ensure only one checkbox is selected
  const handleCheckboxClick = (name) => {
    const checkboxes = ["consent1", "consent2"];
    for (const checkbox of checkboxes) {
      if (checkbox === name) {
        // Set the clicked checkbox to its opposite value
        setValue(name, !watchFields[name]);
      } else {
        // Deselect other checkboxes
        setValue(checkbox, false);
      }
    }
  };

  // Handle checkbox click to ensure only one checkbox is selected
  const handleSensitiveCheckboxClick = (name) => {
    const checkboxes = ["consent3", "consent4"];
    for (const checkbox of checkboxes) {
      if (checkbox === name) {
        // Set the clicked checkbox to its opposite value
        setValue(name, !watchFields[name]);
      } else {
        // Deselect other checkboxes
        setValue(checkbox, false);
      }
    }
  };

  return (
    <div>
      <Modal
        dialogClassName="terms-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
      >
        <div className="d-flex justify-content-end p-3">
          <RiCloseCircleFill
            style={{ fontSize: "23px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </div>
        <Modal.Header className="terms-conditions-modelHeader">
          <h5 className="terms-conditions-modelHeader-name py-3">
            Terms and Conditions
          </h5>
        </Modal.Header>
        <div className="terms-paragraph px-5 py-3" style={{ color: "#103A5D" }}>
          <p className="terms-content">
            <strong>CONSENT FORM</strong> have thoroughly read and understood
            the Privacy Noticeregarding the collection, use, disclosure,
            transfer and processing of my Personal Data, as well as acknowledge
            my rights that I am entitled under the PDPA and other related laws.
            I understand that the absence of my consent may affect the business
            relationship with AGC Vinythai Pcl. (Company). I also understand
            that I can change my mind at any time by withdrawing my consent, and
            that the Company will treat my Personal Data with respect.
          </p>

          <p className="terms-content">
            I confirm my intention and decision by inserting a ✓in the box
            corresponding to the content that as follows
          </p>

          <p>
            <strong>General Personal Data:</strong>
          </p>
          <input
            type="checkbox"
            {...register("consent1", { require: true })}
            onClick={() => handleCheckboxClick("consent1")}
          />
          <span className="px-2 terms-content">
            {" "}
            <span style={{ textDecorationLine: "underline" }}>
              I decline to give{" "}
            </span>{" "}
            my consent to the Company to collect, use, disclose, transfer and/or
            process my <strong>General Personal Data</strong> for the Company
            work.
          </span>
          <br />
          <input
            type="checkbox"
            {...register("consent2", { require: true })}
            onClick={() => handleCheckboxClick("consent2")}
          />
          <span className="px-2">
            <span style={{ textDecorationLine: "underline" }}>I do give</span>{" "}
            my consent to the Company to collect, use, disclose, transfer and/or
            process my <strong>General Personal Data</strong> for the Company
            work.
          </span>

          <p className="pt-3">
            <strong>Sensitive Personal Data:</strong>
          </p>
          <input
            type="checkbox"
            {...register("consent3", { require: true })}
            onClick={() => handleSensitiveCheckboxClick("consent3")}
          />
          <span className="px-2">
            <span style={{ textDecorationLine: "underline" }}>
              I decline to give
            </span>{" "}
            my explicit consent to the Company to collect, use, disclose,
            transfer and/or process my{" "}
            <strong> Sensitive Personal Data </strong>for the Company work.
          </span>
          <br />
          <input
            type="checkbox"
            {...register("consent4", { require: true })}
            onClick={() => handleSensitiveCheckboxClick("consent4")}
          />
          <span className="px-2">
            <span style={{ textDecorationLine: "underline" }}>I do give</span>{" "}
            my explicit consent to the Company to collect, use, disclose,
            transfer and/or process my{" "}
            <strong> Sensitive Personal Data </strong> for the Company work
          </span>

          <div className="d-flex justify-content-center align-items-center pb-5 pt-3">
            <strong
              style={{
                borderRight: "2px solid #ebebeb",
                padding: "10px 50px",
              }}
              className="mx-4"
            >
              <a href="https://agcvinythai.com/pdpa/" target="_blank">
                Privacy Policy
              </a>
            </strong>
            <strong>
              <a href="https://agcvinythai.com/pdpa/" target="_blank">
                AVT Protection policy
              </a>
            </strong>
          </div>

          <div className="d-flex align-items-center justify-content-center py-3">
            <button className="decline-btn mx-5" onClick={handleClose}>
              Decline
            </button>
            <button
              className={`${!isAgreed ? "disabled-btn" : "accept-btn "}`}
              onClick={onClickVendor}
              disabled={!isAgreed}
            >
              Accept
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TermsAndConditions;
